/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCalculatorInputData = /* GraphQL */ `
  subscription OnCreateCalculatorInputData {
    onCreateCalculatorInputData {
      id
      name
      type
      status
      calculationType
      selectedAssetType
      selectedJobType
      selectedTargetSystem
      selectedScopingComplexity
      selectedPriceList
      jiraEpic
      jiraSummary
      reuseRatioPercentage
      discountPercentage
      checkboxes {
        Scoping
        Design
        Deploy
        Campaign_Execution
      }
      numberOfSlides {
        simple {
          imageBased
          html5
        }
        medium {
          imageBased
          html5
        }
        complex {
          imageBased
          html5
        }
      }
      jobData {
        id
        jobs
        services
        createdAt
        updatedAt
      }
      createdBy
      owner
      currentApproverId
      approvedOrRejectedBy
      rejectedComment
      approvers
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCalculatorInputData = /* GraphQL */ `
  subscription OnUpdateCalculatorInputData {
    onUpdateCalculatorInputData {
      id
      name
      type
      status
      calculationType
      selectedAssetType
      selectedJobType
      selectedTargetSystem
      selectedScopingComplexity
      selectedPriceList
      jiraEpic
      jiraSummary
      reuseRatioPercentage
      discountPercentage
      checkboxes {
        Scoping
        Design
        Deploy
        Campaign_Execution
      }
      numberOfSlides {
        simple {
          imageBased
          html5
        }
        medium {
          imageBased
          html5
        }
        complex {
          imageBased
          html5
        }
      }
      jobData {
        id
        jobs
        services
        createdAt
        updatedAt
      }
      createdBy
      owner
      currentApproverId
      approvedOrRejectedBy
      rejectedComment
      approvers
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCalculatorInputData = /* GraphQL */ `
  subscription OnDeleteCalculatorInputData {
    onDeleteCalculatorInputData {
      id
      name
      type
      status
      calculationType
      selectedAssetType
      selectedJobType
      selectedTargetSystem
      selectedScopingComplexity
      selectedPriceList
      jiraEpic
      jiraSummary
      reuseRatioPercentage
      discountPercentage
      checkboxes {
        Scoping
        Design
        Deploy
        Campaign_Execution
      }
      numberOfSlides {
        simple {
          imageBased
          html5
        }
        medium {
          imageBased
          html5
        }
        complex {
          imageBased
          html5
        }
      }
      jobData {
        id
        jobs
        services
        createdAt
        updatedAt
      }
      createdBy
      owner
      currentApproverId
      approvedOrRejectedBy
      rejectedComment
      approvers
      createdAt
      updatedAt
    }
  }
`;
export const onCreateJobData = /* GraphQL */ `
  subscription OnCreateJobData {
    onCreateJobData {
      id
      jobs
      services
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateJobData = /* GraphQL */ `
  subscription OnUpdateJobData {
    onUpdateJobData {
      id
      jobs
      services
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteJobData = /* GraphQL */ `
  subscription OnDeleteJobData {
    onDeleteJobData {
      id
      jobs
      services
      createdAt
      updatedAt
    }
  }
`;
