/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCalculatorInputData = /* GraphQL */ `
  query GetCalculatorInputData($id: ID!) {
    getCalculatorInputData(id: $id) {
      id
      name
      type
      status
      calculationType
      selectedAssetType
      selectedJobType
      selectedTargetSystem
      selectedScopingComplexity
      selectedPriceList
      jiraEpic
      jiraSummary
      reuseRatioPercentage
      discountPercentage
      checkboxes {
        Scoping
        Design
        Deploy
        Campaign_Execution
      }
      numberOfSlides {
        simple {
          imageBased
          html5
        }
        medium {
          imageBased
          html5
        }
        complex {
          imageBased
          html5
        }
      }
      jobData {
        id
        jobs
        services
        createdAt
        updatedAt
      }
      createdBy
      owner
      currentApproverId
      approvedOrRejectedBy
      rejectedComment
      approvers
      createdAt
      updatedAt
    }
  }
`;
export const listCalculatorInputDatas = /* GraphQL */ `
  query ListCalculatorInputDatas(
    $filter: ModelCalculatorInputDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalculatorInputDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        status
        calculationType
        selectedAssetType
        selectedJobType
        selectedTargetSystem
        selectedScopingComplexity
        selectedPriceList
        jiraEpic
        jiraSummary
        reuseRatioPercentage
        discountPercentage
        checkboxes {
          Scoping
          Design
          Deploy
          Campaign_Execution
        }
        jobData {
          id
          jobs
          services
          createdAt
          updatedAt
        }
        createdBy
        owner
        currentApproverId
        approvedOrRejectedBy
        rejectedComment
        approvers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJobData = /* GraphQL */ `
  query GetJobData($id: ID!) {
    getJobData(id: $id) {
      id
      jobs
      services
      createdAt
      updatedAt
    }
  }
`;
export const listJobDatas = /* GraphQL */ `
  query ListJobDatas(
    $filter: ModelJobDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        jobs
        services
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByDate = /* GraphQL */ `
  query ItemsByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalculatorInputDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        status
        calculationType
        selectedAssetType
        selectedJobType
        selectedTargetSystem
        selectedScopingComplexity
        selectedPriceList
        jiraEpic
        jiraSummary
        reuseRatioPercentage
        discountPercentage
        checkboxes {
          Scoping
          Design
          Deploy
          Campaign_Execution
        }
        jobData {
          id
          jobs
          services
          createdAt
          updatedAt
        }
        createdBy
        owner
        currentApproverId
        approvedOrRejectedBy
        rejectedComment
        approvers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByUpdated = /* GraphQL */ `
  query ItemsByUpdated(
    $type: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalculatorInputDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByUpdated(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        status
        calculationType
        selectedAssetType
        selectedJobType
        selectedTargetSystem
        selectedScopingComplexity
        selectedPriceList
        jiraEpic
        jiraSummary
        reuseRatioPercentage
        discountPercentage
        checkboxes {
          Scoping
          Design
          Deploy
          Campaign_Execution
        }
        jobData {
          id
          jobs
          services
          createdAt
          updatedAt
        }
        createdBy
        owner
        currentApproverId
        approvedOrRejectedBy
        rejectedComment
        approvers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
