import { API } from 'aws-amplify';

export default class Jira {
  static getIsEpicData(epicID: string) {
    return API.get('jiraapi', '/epic', {
      queryStringParameters: {
        id: epicID,
      },
    });
  }
}
