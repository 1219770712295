































































































































































































































































import Vue from 'vue';
import { CalculatorInputData } from '@/types/input/CalculatorInputData';
import Utils from '@/helpers/utils';
import CustomRateCard from '@/data-sources/CustomRateCard';

export default Vue.extend({
  name: 'CustomCalculation',
  props: {
    inset: {
      type: Object as () => CalculatorInputData,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    isLocked: {
      type: Boolean,
    },
    isLockedForNonApproverDuringNeedsApproval: {
      type: Boolean,
    },
    isNotInDelivery: {
      type: Boolean,
    },
  },
  data: () => ({
    previousPriceList: '',
    panels: [1],
    panelsDisabled: false,
    templateService: {
      name: '',
      total: 0,
      innerTotal: 0,
      rows: [],
    },
    templateRow: {
      team: 'All',
      specialist: '',
      rate: '0',
      measurementType: 'hourly rate',
      quantity: '0',
      discountPercentage: '0',
      cost: 0,
      innerRate: 0.0,
      innerCost: 0.0,
    },
    services: [],
    otherServices: 'other services',
    totalWithoutDiscount: 0.0,
  }),
  created() {
    this.previousPriceList = this.inset.selectedPriceList;
    this.services = JSON.parse(this.inset.jobData.services);
    if (this.services.length === 0) {
      this.addService();
      this.setInsetServicesField();
      this.$emit('setBackUpForComparisonOnPageLeave');
    }
    if (!this.isNotInDelivery) {
      this.panels = [0];
    }
  },
  methods: {
    // @ts-ignore
    specialistChange(row) {
      if (row.team === 'All') {
        row.team = CustomRateCard
          .getSpecialistData(row.specialist, this.inset.selectedPriceList)!.product;
      }
    },
    // @ts-ignore
    teamListChange(row) {
      if (this.specialistList(row.team).length === 1) {
        row.specialist = this.specialistList(row.team)[0].id;
      } else {
        row.specialist = '';
        row.rate = 0;
        row.innerRate = 0;
        row.measurementType = 'hourly rate';
      }
    },
    addService() {
      // @ts-ignore
      this.services.push(JSON.parse(JSON.stringify(this.templateService)));
      this.addRow(this.services[this.services.length - 1]);
      if (!this.panels.includes(this.services.length)) {
        this.panels.push(this.services.length); // expand new panel
      }
    },
    removeService(index: number) {
      this.panels = [];
      if (this.services.length > 1) {
        this.services.splice(index, 1);
      }
    },
    // @ts-ignore
    addRow(service) {
      service.rows.push(JSON.parse(JSON.stringify(this.templateRow)));
    },
    // @ts-ignore
    removeRow(service, rowIndex) {
      service.rows.splice(rowIndex, 1);
    },
    autoCorrectNumber(obj: Record<string, number>, key: string, min = 0) {
      if (obj[key] < min) {
        obj[key] = min;
      }
      if (obj[key].toString() === '') {
        obj[key] = 0;
      }
    },
    autoCorrectPercentageInputField(obj: Record<string, number>, min = 0, max = 99) {
      if (obj.discountPercentage < min) {
        obj.discountPercentage = min;
      }
      if (obj.discountPercentage > max) {
        obj.discountPercentage = max;
      }
      if (obj.discountPercentage.toString() === '') {
        obj.discountPercentage = min;
      }
    },
    calculateRowCost(row: Record<any, number | string>) {
      row.cost = Utils.roundDecimal(parseFloat(String(row.rate))
        * parseFloat(String(row.quantity))
          * (1 - parseFloat(String(row.discountPercentage)) / 100));
    },
    calculateInnerRowCost(row: Record<any, number | string>) {
      if (row.team === 'other services') {
        row.innerRate = row.rate;
      }
      row.innerCost = Utils.roundDecimal(parseFloat(String(row.innerRate))
        * parseFloat(String(row.quantity)));
    },
    calculateServiceTotal(service: Record<any, any>) {
      service.total = service.rows.reduce(
        (acc: number, row: Record<any, number>) => acc + row.cost, 0.0,
      );
    },
    calculateServiceInnerTotal(service: Record<any, any>) {
      service.innerTotal = service.rows.reduce(
        (acc: number, row: Record<any, number>) => acc + row.innerCost, 0.0,
      );
    },
    setInsetServicesField() {
      this.inset.jobData.services = JSON.stringify(this.services);
    },
    specialistList(team: string) {
      return CustomRateCard.getSpecialistList(this.inset.selectedPriceList, team);
    },
    setSpecialistRateAndMeasurement(row: Record<any, string>) {
      if (row.specialist !== '' && row.specialist !== 'other services') {
        const data = CustomRateCard.getSpecialistData(row.specialist, this.inset.selectedPriceList);
        row.rate = String(data!.rate);
        // @ts-ignore
        row.innerRate = CustomRateCard.getInnerRate(row.specialist, this.inset.selectedPriceList);
        row.measurementType = data!.unit;
      }
    },
    cleanUpServicesInCasePriceListChanged() {
      if (this.previousPriceList !== this.inset.selectedPriceList) {
        this.services.forEach((service) => {
          // @ts-ignore
          service.rows = [];
          this.addRow(service);
        });
      }
      this.previousPriceList = this.inset.selectedPriceList;
    },
    calculateTotalWithoutDiscount() {
      const copy = JSON.parse(JSON.stringify(this.services));
      // @ts-ignore
      copy.forEach((service) => {
        // @ts-ignore
        service.rows.forEach((row) => {
          row.discountPercentage = 0;
          this.setSpecialistRateAndMeasurement(row);
          this.calculateRowCost(row);
        });
      });
      // @ts-ignore
      copy.forEach((service) => this.calculateServiceTotal(service));
      // @ts-ignore
      const total = Utils.roundDecimal(copy.reduce((acc, service) => acc + service.total, 0.0));
      this.$emit('servicesTotalWithoutDiscountChange', total);
      this.totalWithoutDiscount = total;
    },
  },
  computed: {
    teamList() {
      return CustomRateCard.getTeamsList(this.inset.selectedPriceList);
    },
    computedServices() {
      this.cleanUpServicesInCasePriceListChanged();
      this.services.forEach((service) => {
        // @ts-ignore
        service.rows.forEach((row) => {
          this.setSpecialistRateAndMeasurement(row);
          this.calculateRowCost(row);
          this.calculateInnerRowCost(row);
        });
      });
      this.services.forEach((service) => this.calculateServiceTotal(service));
      this.services.forEach((service) => this.calculateServiceInnerTotal(service));
      this.setInsetServicesField();
      this.calculateTotalWithoutDiscount();
      return this.services;
    },
    absoluteTotal() {
      const total = Utils.roundDecimal(this.services.reduce(
        // @ts-ignore
        (acc, service) => acc + service.total, 0.0,
      ));
      this.$emit('servicesTotalChange', total);
      return total;
    },
    absoluteInnerTotal() {
      // @ts-ignore
      const total = this.services.reduce((acc, service) => acc + service.innerTotal, 0.0);
      this.$emit('servicesInnerTotalChange', total);
      return total;
    },
    grossProfit() {
      // @ts-ignore
      const profit = this.absoluteTotal - this.absoluteInnerTotal;
      this.$emit('servicesGrossProfitChange', profit);
      return profit;
    },
    grossProfitPercentage() {
      // @ts-ignore
      if (this.absoluteTotal === 0) {
        return 0;
      }
      // @ts-ignore
      return (this.grossProfit / this.absoluteTotal) * 100;
    },
    measurementTypeList() {
      return ['hourly rate', 'licenses', 'services', 'items'];
    },
    hasDiscount() {
      // @ts-ignore
      return this.services.some((item) => item.rows.some((row) => row.discountPercentage > 0));
    },
    discountAmount() {
      // @ts-ignore
      return Utils.roundDecimal(this.totalWithoutDiscount - this.absoluteTotal);
    },
  },
});
