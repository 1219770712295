import Vue from 'vue';
import '@aws-amplify/ui-vue';
import { Amplify } from 'aws-amplify';
// import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import currencySign from '@/filters/currency';
import toFixed from '@/filters/toFixed';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import '@/styles/main.css';

import awsExports from './aws-exports';

// important: do not specify protocol in domain value (not https://)
Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_ml6GMXwRr',
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID || '6fp11qok6df9j32bo7qu044gc3',
    authenticationFlowType: 'REFRESH_TOKEN_AUTH',
    // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
    oauth: {
      domain: 'calc.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: process.env.VUE_APP_REDIRECT_SIGN_IN || 'http://localhost:8080/',
      redirectSignOut: process.env.VUE_APP_REDIRECT_SIGN_OUT || 'http://localhost:8080/?signed_out=true',
      responseType: 'code',
    },
  },
  API: {
    aws_appsync_graphqlEndpoint: awsExports.aws_appsync_graphqlEndpoint,
    aws_project_region: awsExports.aws_appsync_region,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_cloud_logic_custom: awsExports.aws_cloud_logic_custom,
  },
});

// const client = new AWSAppSyncClient({
//   url: awsExports.aws_appsync_graphqlEndpoint,
//   region: awsExports.aws_appsync_region,
//   auth: {
//     type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
//     jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
//   },
// });
// console.log('AWSAppSyncClient', client);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.productionTip = false;

Vue.filter('toFixed', toFixed);
Vue.filter('currencySign', currencySign);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
})
  .$mount('#app');
