import CustomRateCard from '@/data-sources/CustomRateCard';

export default class RateCard {
  static getRateObject(priceList: string, workerKey: string) {
    return this.data.find((item) => item.priceList === priceList
      && item.role === workerKey);
  }

  static getInnerRate(workerKey: string, priceList: string) {
    const specialistData = this.getRateObject(priceList, workerKey);
    // @ts-ignore
    return specialistData.innerRate;
  }

  static getRate(priceList: string, workerKey: string) {
    return this.getRateObject(priceList, workerKey)?.rate;
  }

  static getCurrency(priceList: string) {
    return CustomRateCard.getDataForStandardCalculation()
      .find((item) => item.priceList === priceList)?.currency;
  }

  static getPriceList() {
    // Price lists and currency are not the same in standard and custom rate cards!!!
    // But standard price list names  should be included in custom rate card
    const names: string[] = [];
    this.data.forEach((item) => {
      if (!names.includes(item.priceList)) {
        names.push(item.priceList);
      }
    });
    return names.sort();
  }

  private static data = [
    {
      priceList: 'Novo Nordisk GL',
      title: 'Liaison Manager',
      rate: 28,
      innerRate: 7.9,
      currency: 'EUR',
      role: 'LM',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'Channel Manager',
      rate: 31,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'CH',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'Medical Marketing Expert',
      rate: 25,
      innerRate: 9.7,
      currency: 'EUR',
      role: 'MedAdv',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'Project Manager',
      rate: 27,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'CrtvPM',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'Senior Graphic Designer',
      rate: 25,
      innerRate: 9.4,
      currency: 'EUR',
      role: 'SDsgn',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'Graphic Designer',
      rate: 23,
      innerRate: 5.5,
      currency: 'EUR',
      role: 'Dsgnr',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'Proofreader',
      rate: 23,
      innerRate: 3,
      currency: 'EUR',
      role: 'PR/QA',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'Project Manager',
      rate: 27,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'PM',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'Frontend Developer',
      rate: 25,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'SDvlpr',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'HTML 5 Coder',
      rate: 23,
      innerRate: 3.6,
      currency: 'EUR',
      role: 'Dvlpr',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'Senior QA Engineer',
      rate: 25,
      innerRate: 6.8,
      currency: 'EUR',
      role: 'QAMngr',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'QA Engineer',
      rate: 23,
      innerRate: 4.2,
      currency: 'EUR',
      role: 'QA',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'Project Manager',
      rate: 27,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'DplmMngr',
    },
    {
      priceList: 'Novo Nordisk GL',
      title: 'Content Deployment Engineer',
      rate: 31,
      innerRate: 4.5,
      currency: 'EUR',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Biogen GL',
      title: 'Liaison Manager',
      rate: 30,
      innerRate: 7.9,
      currency: 'EUR',
      role: 'LM',
    },
    {
      priceList: 'Biogen GL',
      title: 'Channel Manager',
      rate: 28,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'CH',
    },
    {
      priceList: 'Biogen GL',
      title: 'Medical Marketing Expert',
      rate: 30,
      innerRate: 9.7,
      currency: 'EUR',
      role: 'MedAdv',
    },
    {
      priceList: 'Biogen GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'CrtvPM',
    },
    {
      priceList: 'Biogen GL',
      title: 'Senior Graphic Designer',
      rate: 30,
      innerRate: 9.4,
      currency: 'EUR',
      role: 'SDsgn',
    },
    {
      priceList: 'Biogen GL',
      title: 'Graphic Designer',
      rate: 30,
      innerRate: 5.5,
      currency: 'EUR',
      role: 'Dsgnr',
    },
    {
      priceList: 'Biogen GL',
      title: 'Proofreader',
      rate: 30,
      innerRate: 3,
      currency: 'EUR',
      role: 'PR/QA',
    },
    {
      priceList: 'Biogen GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'PM',
    },
    {
      priceList: 'Biogen GL',
      title: 'Frontend Developer',
      rate: 28,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'SDvlpr',
    },
    {
      priceList: 'Biogen GL',
      title: 'HTML 5 Coder',
      rate: 25,
      innerRate: 3.6,
      currency: 'EUR',
      role: 'Dvlpr',
    },
    {
      priceList: 'Biogen GL',
      title: 'Senior QA Engineer',
      rate: 28,
      innerRate: 6.8,
      currency: 'EUR',
      role: 'QAMngr',
    },
    {
      priceList: 'Biogen GL',
      title: 'QA Engineer',
      rate: 28,
      innerRate: 4.2,
      currency: 'EUR',
      role: 'QA',
    },
    {
      priceList: 'Biogen GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'DplmMngr',
    },
    {
      priceList: 'Biogen GL',
      title: 'Content Deployment Engineer',
      rate: 30,
      innerRate: 4.5,
      currency: 'EUR',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Viatris GL',
      title: 'Liaison Manager',
      rate: 35,
      innerRate: 9.4,
      currency: 'USD',
      role: 'LM',
    },
    {
      priceList: 'Viatris GL',
      title: 'Channel Manager',
      rate: 35,
      innerRate: 7.7,
      currency: 'USD',
      role: 'CH',
    },
    {
      priceList: 'Viatris GL',
      title: 'Medical Marketing Expert',
      rate: 30,
      innerRate: 11.5,
      currency: 'USD',
      role: 'MedAdv',
    },
    {
      priceList: 'Viatris GL',
      title: 'Project Manager',
      rate: 28,
      innerRate: 11.2,
      currency: 'USD',
      role: 'CrtvPM',
    },
    {
      priceList: 'Viatris GL',
      title: 'Senior Graphic Designer',
      rate: 35,
      innerRate: 11.2,
      currency: 'USD',
      role: 'SDsgn',
    },
    {
      priceList: 'Viatris GL',
      title: 'Graphic Designer',
      rate: 30,
      innerRate: 6.4,
      currency: 'USD',
      role: 'Dsgnr',
    },
    {
      priceList: 'Viatris GL',
      title: 'Proofreader',
      rate: 26,
      innerRate: 3.5,
      currency: 'USD',
      role: 'PR/QA',
    },
    {
      priceList: 'Viatris GL',
      title: 'Project Manager',
      rate: 28,
      innerRate: 11.2,
      currency: 'USD',
      role: 'PM',
    },
    {
      priceList: 'Viatris GL',
      title: 'Frontend Developer',
      rate: 35,
      innerRate: 7.7,
      currency: 'USD',
      role: 'SDvlpr',
    },
    {
      priceList: 'Viatris GL',
      title: 'HTML 5 Coder',
      rate: 26,
      innerRate: 4.2,
      currency: 'USD',
      role: 'Dvlpr',
    },
    {
      priceList: 'Viatris GL',
      title: 'Senior QA Engineer',
      rate: 35,
      innerRate: 8,
      currency: 'USD',
      role: 'QAMngr',
    },
    {
      priceList: 'Viatris GL',
      title: 'QA Engineer',
      rate: 26,
      innerRate: 5,
      currency: 'USD',
      role: 'QA',
    },
    {
      priceList: 'Viatris GL',
      title: 'Project Manager',
      rate: 28,
      innerRate: 11.2,
      currency: 'USD',
      role: 'DplmMngr',
    },
    {
      priceList: 'Viatris GL',
      title: 'Content Deployment Engineer',
      rate: 28,
      innerRate: 5.3,
      currency: 'USD',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Viatris UA',
      title: 'Liaison Manager',
      rate: 900,
      innerRate: 247.8,
      currency: 'UAH',
      role: 'LM',
    },
    {
      priceList: 'Viatris UA',
      title: 'Channel Manager',
      rate: 900,
      innerRate: 202.7,
      currency: 'UAH',
      role: 'CH',
    },
    {
      priceList: 'Viatris UA',
      title: 'Medical Marketing Expert',
      rate: 900,
      innerRate: 304.8,
      currency: 'UAH',
      role: 'MedAdv',
    },
    {
      priceList: 'Viatris UA',
      title: 'Project Manager',
      rate: 900,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'CrtvPM',
    },
    {
      priceList: 'Viatris UA',
      title: 'Senior Graphic Designer',
      rate: 900,
      innerRate: 295.5,
      currency: 'UAH',
      role: 'SDsgn',
    },
    {
      priceList: 'Viatris UA',
      title: 'Graphic Designer',
      rate: 900,
      innerRate: 170.7,
      currency: 'UAH',
      role: 'Dsgnr',
    },
    {
      priceList: 'Viatris UA',
      title: 'Proofreader',
      rate: 900,
      innerRate: 93.5,
      currency: 'UAH',
      role: 'PR/QA',
    },
    {
      priceList: 'Viatris UA',
      title: 'Project Manager',
      rate: 900,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'PM',
    },
    {
      priceList: 'Viatris UA',
      title: 'Frontend Developer',
      rate: 900,
      innerRate: 204.6,
      currency: 'UAH',
      role: 'SDvlpr',
    },
    {
      priceList: 'Viatris UA',
      title: 'HTML 5 Coder',
      rate: 900,
      innerRate: 111.6,
      currency: 'UAH',
      role: 'Dvlpr',
    },
    {
      priceList: 'Viatris UA',
      title: 'Senior QA Engineer',
      rate: 900,
      innerRate: 212,
      currency: 'UAH',
      role: 'QAMngr',
    },
    {
      priceList: 'Viatris UA',
      title: 'QA Engineer',
      rate: 900,
      innerRate: 131.7,
      currency: 'UAH',
      role: 'QA',
    },
    {
      priceList: 'Viatris UA',
      title: 'Project Manager',
      rate: 900,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'DplmMngr',
    },
    {
      priceList: 'Viatris UA',
      title: 'Content Deployment Engineer',
      rate: 900,
      innerRate: 139.4,
      currency: 'UAH',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Liaison Manager',
      rate: 28,
      innerRate: 7.9,
      currency: 'EUR',
      role: 'LM',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Channel Manager',
      rate: 28,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'CH',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Medical Marketing Expert',
      rate: 28,
      innerRate: 9.7,
      currency: 'EUR',
      role: 'MedAdv',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'CrtvPM',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Senior Graphic Designer',
      rate: 28,
      innerRate: 9.4,
      currency: 'EUR',
      role: 'SDsgn',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Graphic Designer',
      rate: 28,
      innerRate: 5.5,
      currency: 'EUR',
      role: 'Dsgnr',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Proofreader',
      rate: 28,
      innerRate: 3,
      currency: 'EUR',
      role: 'PR/QA',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'PM',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Frontend Developer',
      rate: 28,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'SDvlpr',
    },
    {
      priceList: 'Abbvie GL',
      title: 'HTML 5 Coder',
      rate: 28,
      innerRate: 3.6,
      currency: 'EUR',
      role: 'Dvlpr',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Senior QA Engineer',
      rate: 28,
      innerRate: 6.8,
      currency: 'EUR',
      role: 'QAMngr',
    },
    {
      priceList: 'Abbvie GL',
      title: 'QA Engineer',
      rate: 28,
      innerRate: 4.2,
      currency: 'EUR',
      role: 'QA',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'DplmMngr',
    },
    {
      priceList: 'Abbvie GL',
      title: 'Content Deployment Engineer',
      rate: 28,
      innerRate: 4.5,
      currency: 'EUR',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Servier UA',
      title: 'Liaison Manager',
      rate: 600,
      innerRate: 247.8,
      currency: 'UAH',
      role: 'LM',
    },
    {
      priceList: 'Servier UA',
      title: 'Channel Manager',
      rate: 600,
      innerRate: 202.7,
      currency: 'UAH',
      role: 'CH',
    },
    {
      priceList: 'Servier UA',
      title: 'Medical Marketing Expert',
      rate: 600,
      innerRate: 304.8,
      currency: 'UAH',
      role: 'MedAdv',
    },
    {
      priceList: 'Servier UA',
      title: 'Project Manager',
      rate: 600,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'CrtvPM',
    },
    {
      priceList: 'Servier UA',
      title: 'Senior Graphic Designer',
      rate: 550,
      innerRate: 295.5,
      currency: 'UAH',
      role: 'SDsgn',
    },
    {
      priceList: 'Servier UA',
      title: 'Graphic Designer',
      rate: 550,
      innerRate: 170.7,
      currency: 'UAH',
      role: 'Dsgnr',
    },
    {
      priceList: 'Servier UA',
      title: 'Proofreader',
      rate: 600,
      innerRate: 93.5,
      currency: 'UAH',
      role: 'PR/QA',
    },
    {
      priceList: 'Servier UA',
      title: 'Project Manager',
      rate: 600,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'PM',
    },
    {
      priceList: 'Servier UA',
      title: 'Frontend Developer',
      rate: 600,
      innerRate: 204.6,
      currency: 'UAH',
      role: 'SDvlpr',
    },
    {
      priceList: 'Servier UA',
      title: 'HTML 5 Coder',
      rate: 600,
      innerRate: 111.6,
      currency: 'UAH',
      role: 'Dvlpr',
    },
    {
      priceList: 'Servier UA',
      title: 'Senior QA Engineer',
      rate: 550,
      innerRate: 212,
      currency: 'UAH',
      role: 'QAMngr',
    },
    {
      priceList: 'Servier UA',
      title: 'QA Engineer',
      rate: 550,
      innerRate: 131.7,
      currency: 'UAH',
      role: 'QA',
    },
    {
      priceList: 'Servier UA',
      title: 'Project Manager',
      rate: 600,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'DplmMngr',
    },
    {
      priceList: 'Servier UA',
      title: 'Content Deployment Engineer',
      rate: 600,
      innerRate: 139.4,
      currency: 'UAH',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Bayer UA',
      title: 'Liaison Manager',
      rate: 28,
      innerRate: 9.4,
      currency: 'USD',
      role: 'LM',
    },
    {
      priceList: 'Bayer UA',
      title: 'Channel Manager',
      rate: 28,
      innerRate: 7.7,
      currency: 'USD',
      role: 'CH',
    },
    {
      priceList: 'Bayer UA',
      title: 'Medical Marketing Expert',
      rate: 28,
      innerRate: 11.5,
      currency: 'USD',
      role: 'MedAdv',
    },
    {
      priceList: 'Bayer UA',
      title: 'Project Manager',
      rate: 28,
      innerRate: 11.2,
      currency: 'USD',
      role: 'CrtvPM',
    },
    {
      priceList: 'Bayer UA',
      title: 'Senior Graphic Designer',
      rate: 30,
      innerRate: 11.2,
      currency: 'USD',
      role: 'SDsgn',
    },
    {
      priceList: 'Bayer UA',
      title: 'Graphic Designer',
      rate: 30,
      innerRate: 6.4,
      currency: 'USD',
      role: 'Dsgnr',
    },
    {
      priceList: 'Bayer UA',
      title: 'Proofreader',
      rate: 28,
      innerRate: 3.5,
      currency: 'USD',
      role: 'PR/QA',
    },
    {
      priceList: 'Bayer UA',
      title: 'Project Manager',
      rate: 28,
      innerRate: 11.2,
      currency: 'USD',
      role: 'PM',
    },
    {
      priceList: 'Bayer UA',
      title: 'Frontend Developer',
      rate: 30,
      innerRate: 7.7,
      currency: 'USD',
      role: 'SDvlpr',
    },
    {
      priceList: 'Bayer UA',
      title: 'HTML 5 Coder',
      rate: 30,
      innerRate: 4.2,
      currency: 'USD',
      role: 'Dvlpr',
    },
    {
      priceList: 'Bayer UA',
      title: 'Senior QA Engineer',
      rate: 30,
      innerRate: 8,
      currency: 'USD',
      role: 'QAMngr',
    },
    {
      priceList: 'Bayer UA',
      title: 'QA Engineer',
      rate: 30,
      innerRate: 5,
      currency: 'USD',
      role: 'QA',
    },
    {
      priceList: 'Bayer UA',
      title: 'Project Manager',
      rate: 28,
      innerRate: 11.2,
      currency: 'USD',
      role: 'DplmMngr',
    },
    {
      priceList: 'Bayer UA',
      title: 'Content Deployment Engineer',
      rate: 30,
      innerRate: 5.3,
      currency: 'USD',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Bayer GL',
      title: 'Liaison Manager',
      rate: 35,
      innerRate: 7.9,
      currency: 'EUR',
      role: 'LM',
    },
    {
      priceList: 'Bayer GL',
      title: 'Channel Manager',
      rate: 35,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'CH',
    },
    {
      priceList: 'Bayer GL',
      title: 'Medical Marketing Expert',
      rate: 35,
      innerRate: 9.7,
      currency: 'EUR',
      role: 'MedAdv',
    },
    {
      priceList: 'Bayer GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'CrtvPM',
    },
    {
      priceList: 'Bayer GL',
      title: 'Senior Graphic Designer',
      rate: 30,
      innerRate: 9.4,
      currency: 'EUR',
      role: 'SDsgn',
    },
    {
      priceList: 'Bayer GL',
      title: 'Graphic Designer',
      rate: 28,
      innerRate: 5.5,
      currency: 'EUR',
      role: 'Dsgnr',
    },
    {
      priceList: 'Bayer GL',
      title: 'Proofreader',
      rate: 28,
      innerRate: 3,
      currency: 'EUR',
      role: 'PR/QA',
    },
    {
      priceList: 'Bayer GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'PM',
    },
    {
      priceList: 'Bayer GL',
      title: 'Frontend Developer',
      rate: 30,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'SDvlpr',
    },
    {
      priceList: 'Bayer GL',
      title: 'HTML 5 Coder',
      rate: 28,
      innerRate: 3.6,
      currency: 'EUR',
      role: 'Dvlpr',
    },
    {
      priceList: 'Bayer GL',
      title: 'Senior QA Engineer',
      rate: 28,
      innerRate: 6.8,
      currency: 'EUR',
      role: 'QAMngr',
    },
    {
      priceList: 'Bayer GL',
      title: 'QA Engineer',
      rate: 28,
      innerRate: 4.2,
      currency: 'EUR',
      role: 'QA',
    },
    {
      priceList: 'Bayer GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'DplmMngr',
    },
    {
      priceList: 'Bayer GL',
      title: 'Content Deployment Engineer',
      rate: 30,
      innerRate: 4.5,
      currency: 'EUR',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Abbott RU',
      title: 'Liaison Manager',
      rate: 2190,
      innerRate: 562.6,
      currency: 'RUB',
      role: 'LM',
    },
    {
      priceList: 'Abbott RU',
      title: 'Channel Manager',
      rate: 2190,
      innerRate: 460.3,
      currency: 'RUB',
      role: 'CH',
    },
    {
      priceList: 'Abbott RU',
      title: 'Medical Marketing Expert',
      rate: 2190,
      innerRate: 691.9,
      currency: 'RUB',
      role: 'MedAdv',
    },
    {
      priceList: 'Abbott RU',
      title: 'Project Manager',
      rate: 2190,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'CrtvPM',
    },
    {
      priceList: 'Abbott RU',
      title: 'Senior Graphic Designer',
      rate: 2555,
      innerRate: 670.9,
      currency: 'RUB',
      role: 'SDsgn',
    },
    {
      priceList: 'Abbott RU',
      title: 'Graphic Designer',
      rate: 2190,
      innerRate: 387.5,
      currency: 'RUB',
      role: 'Dsgnr',
    },
    {
      priceList: 'Abbott RU',
      title: 'Proofreader',
      rate: 2190,
      innerRate: 212.4,
      currency: 'RUB',
      role: 'PR/QA',
    },
    {
      priceList: 'Abbott RU',
      title: 'Project Manager',
      rate: 2190,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'PM',
    },
    {
      priceList: 'Abbott RU',
      title: 'Frontend Developer',
      rate: 3285,
      innerRate: 464.5,
      currency: 'RUB',
      role: 'SDvlpr',
    },
    {
      priceList: 'Abbott RU',
      title: 'HTML 5 Coder',
      rate: 2555,
      innerRate: 253.3,
      currency: 'RUB',
      role: 'Dvlpr',
    },
    {
      priceList: 'Abbott RU',
      title: 'Senior QA Engineer',
      rate: 2555,
      innerRate: 481.4,
      currency: 'RUB',
      role: 'QAMngr',
    },
    {
      priceList: 'Abbott RU',
      title: 'QA Engineer',
      rate: 2190,
      innerRate: 299,
      currency: 'RUB',
      role: 'QA',
    },
    {
      priceList: 'Abbott RU',
      title: 'Project Manager',
      rate: 2190,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'DplmMngr',
    },
    {
      priceList: 'Abbott RU',
      title: 'Content Deployment Engineer',
      rate: 2920,
      innerRate: 316.5,
      currency: 'RUB',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Liaison Manager',
      rate: 35,
      innerRate: 7.9,
      currency: 'EUR',
      role: 'LM',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Channel Manager',
      rate: 35,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'CH',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Medical Marketing Expert',
      rate: 45,
      innerRate: 9.7,
      currency: 'EUR',
      role: 'MedAdv',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Project Manager',
      rate: 35,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'CrtvPM',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Senior Graphic Designer',
      rate: 30,
      innerRate: 9.4,
      currency: 'EUR',
      role: 'SDsgn',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Graphic Designer',
      rate: 30,
      innerRate: 5.5,
      currency: 'EUR',
      role: 'Dsgnr',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Proofreader',
      rate: 28,
      innerRate: 3,
      currency: 'EUR',
      role: 'PR/QA',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Project Manager',
      rate: 35,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'PM',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Frontend Developer',
      rate: 38,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'SDvlpr',
    },
    {
      priceList: 'Galapagos GL',
      title: 'HTML 5 Coder',
      rate: 30,
      innerRate: 3.6,
      currency: 'EUR',
      role: 'Dvlpr',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Senior QA Engineer',
      rate: 28,
      innerRate: 6.8,
      currency: 'EUR',
      role: 'QAMngr',
    },
    {
      priceList: 'Galapagos GL',
      title: 'QA Engineer',
      rate: 28,
      innerRate: 4.2,
      currency: 'EUR',
      role: 'QA',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Project Manager',
      rate: 35,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'DplmMngr',
    },
    {
      priceList: 'Galapagos GL',
      title: 'Content Deployment Engineer',
      rate: 35,
      innerRate: 4.5,
      currency: 'EUR',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Danone GL',
      title: 'Liaison Manager',
      rate: 32,
      innerRate: 7.9,
      currency: 'EUR',
      role: 'LM',
    },
    {
      priceList: 'Danone GL',
      title: 'Channel Manager',
      rate: 32,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'CH',
    },
    {
      priceList: 'Danone GL',
      title: 'Medical Marketing Expert',
      rate: 40,
      innerRate: 9.7,
      currency: 'EUR',
      role: 'MedAdv',
    },
    {
      priceList: 'Danone GL',
      title: 'Project Manager',
      rate: 28,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'CrtvPM',
    },
    {
      priceList: 'Danone GL',
      title: 'Senior Graphic Designer',
      rate: 32,
      innerRate: 9.4,
      currency: 'EUR',
      role: 'SDsgn',
    },
    {
      priceList: 'Danone GL',
      title: 'Graphic Designer',
      rate: 24,
      innerRate: 5.5,
      currency: 'EUR',
      role: 'Dsgnr',
    },
    {
      priceList: 'Danone GL',
      title: 'Proofreader',
      rate: 22,
      innerRate: 3,
      currency: 'EUR',
      role: 'PR/QA',
    },
    {
      priceList: 'Danone GL',
      title: 'Project Manager',
      rate: 28,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'PM',
    },
    {
      priceList: 'Danone GL',
      title: 'Frontend Developer',
      rate: 28,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'SDvlpr',
    },
    {
      priceList: 'Danone GL',
      title: 'HTML 5 Coder',
      rate: 24,
      innerRate: 3.6,
      currency: 'EUR',
      role: 'Dvlpr',
    },
    {
      priceList: 'Danone GL',
      title: 'Senior QA Engineer',
      rate: 28,
      innerRate: 6.8,
      currency: 'EUR',
      role: 'QAMngr',
    },
    {
      priceList: 'Danone GL',
      title: 'QA Engineer',
      rate: 22,
      innerRate: 4.2,
      currency: 'EUR',
      role: 'QA',
    },
    {
      priceList: 'Danone GL',
      title: 'Project Manager',
      rate: 28,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'DplmMngr',
    },
    {
      priceList: 'Danone GL',
      title: 'Content Deployment Engineer',
      rate: 28,
      innerRate: 4.5,
      currency: 'EUR',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Merck GL',
      title: 'Liaison Manager',
      rate: 35,
      innerRate: 7.9,
      currency: 'EUR',
      role: 'LM',
    },
    {
      priceList: 'Merck GL',
      title: 'Channel Manager',
      rate: 35,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'CH',
    },
    {
      priceList: 'Merck GL',
      title: 'Medical Marketing Expert',
      rate: 28,
      innerRate: 9.7,
      currency: 'EUR',
      role: 'MedAdv',
    },
    {
      priceList: 'Merck GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'CrtvPM',
    },
    {
      priceList: 'Merck GL',
      title: 'Senior Graphic Designer',
      rate: 28,
      innerRate: 9.4,
      currency: 'EUR',
      role: 'SDsgn',
    },
    {
      priceList: 'Merck GL',
      title: 'Graphic Designer',
      rate: 28,
      innerRate: 5.5,
      currency: 'EUR',
      role: 'Dsgnr',
    },
    {
      priceList: 'Merck GL',
      title: 'Proofreader',
      rate: 28,
      innerRate: 3,
      currency: 'EUR',
      role: 'PR/QA',
    },
    {
      priceList: 'Merck GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'PM',
    },
    {
      priceList: 'Merck GL',
      title: 'Frontend Developer',
      rate: 40,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'SDvlpr',
    },
    {
      priceList: 'Merck GL',
      title: 'HTML 5 Coder',
      rate: 28,
      innerRate: 3.6,
      currency: 'EUR',
      role: 'Dvlpr',
    },
    {
      priceList: 'Merck GL',
      title: 'Senior QA Engineer',
      rate: 28,
      innerRate: 6.8,
      currency: 'EUR',
      role: 'QAMngr',
    },
    {
      priceList: 'Merck GL',
      title: 'QA Engineer',
      rate: 28,
      innerRate: 4.2,
      currency: 'EUR',
      role: 'QA',
    },
    {
      priceList: 'Merck GL',
      title: 'Project Manager',
      rate: 30,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'DplmMngr',
    },
    {
      priceList: 'Merck GL',
      title: 'Content Deployment Engineer',
      rate: 28,
      innerRate: 4.5,
      currency: 'EUR',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Liaison Manager',
      rate: 2250,
      innerRate: 562.6,
      currency: 'RUB',
      role: 'LM',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Channel Manager',
      rate: 2250,
      innerRate: 460.3,
      currency: 'RUB',
      role: 'CH',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Medical Marketing Expert',
      rate: 2025,
      innerRate: 691.9,
      currency: 'RUB',
      role: 'MedAdv',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Project Manager',
      rate: 2250,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'CrtvPM',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Senior Graphic Designer',
      rate: 2520,
      innerRate: 670.9,
      currency: 'RUB',
      role: 'SDsgn',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Graphic Designer',
      rate: 2025,
      innerRate: 387.5,
      currency: 'RUB',
      role: 'Dsgnr',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Proofreader',
      rate: 1925,
      innerRate: 212.4,
      currency: 'RUB',
      role: 'PR/QA',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Project Manager',
      rate: 2250,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'PM',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Frontend Developer',
      rate: 2250,
      innerRate: 464.5,
      currency: 'RUB',
      role: 'SDvlpr',
    },
    {
      priceList: 'Glenmark RU',
      title: 'HTML 5 Coder',
      rate: 1925,
      innerRate: 253.3,
      currency: 'RUB',
      role: 'Dvlpr',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Senior QA Engineer',
      rate: 1925,
      innerRate: 481.4,
      currency: 'RUB',
      role: 'QAMngr',
    },
    {
      priceList: 'Glenmark RU',
      title: 'QA Engineer',
      rate: 1925,
      innerRate: 299,
      currency: 'RUB',
      role: 'QA',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Project Manager',
      rate: 2250,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'DplmMngr',
    },
    {
      priceList: 'Glenmark RU',
      title: 'Content Deployment Engineer',
      rate: 2250,
      innerRate: 316.5,
      currency: 'RUB',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Janssen GL',
      title: 'Liaison Manager',
      rate: 35,
      innerRate: 7.9,
      currency: 'EUR',
      role: 'LM',
    },
    {
      priceList: 'Janssen GL',
      title: 'Channel Manager',
      rate: 35,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'CH',
    },
    {
      priceList: 'Janssen GL',
      title: 'Medical Marketing Expert',
      rate: 28,
      innerRate: 9.7,
      currency: 'EUR',
      role: 'MedAdv',
    },
    {
      priceList: 'Janssen GL',
      title: 'Project Manager',
      rate: 35,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'CrtvPM',
    },
    {
      priceList: 'Janssen GL',
      title: 'Senior Graphic Designer',
      rate: 40,
      innerRate: 9.4,
      currency: 'EUR',
      role: 'SDsgn',
    },
    {
      priceList: 'Janssen GL',
      title: 'Graphic Designer',
      rate: 28,
      innerRate: 5.5,
      currency: 'EUR',
      role: 'Dsgnr',
    },
    {
      priceList: 'Janssen GL',
      title: 'Proofreader',
      rate: 28,
      innerRate: 3,
      currency: 'EUR',
      role: 'PR/QA',
    },
    {
      priceList: 'Janssen GL',
      title: 'Project Manager',
      rate: 35,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'PM',
    },
    {
      priceList: 'Janssen GL',
      title: 'Frontend Developer',
      rate: 40,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'SDvlpr',
    },
    {
      priceList: 'Janssen GL',
      title: 'HTML 5 Coder',
      rate: 30,
      innerRate: 3.6,
      currency: 'EUR',
      role: 'Dvlpr',
    },
    {
      priceList: 'Janssen GL',
      title: 'Senior QA Engineer',
      rate: 40,
      innerRate: 6.8,
      currency: 'EUR',
      role: 'QAMngr',
    },
    {
      priceList: 'Janssen GL',
      title: 'QA Engineer',
      rate: 28,
      innerRate: 4.2,
      currency: 'EUR',
      role: 'QA',
    },
    {
      priceList: 'Janssen GL',
      title: 'Project Manager',
      rate: 35,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'DplmMngr',
    },
    {
      priceList: 'Janssen GL',
      title: 'Content Deployment Engineer',
      rate: 35,
      innerRate: 4.5,
      currency: 'EUR',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Janssen RU',
      title: 'Liaison Manager',
      rate: 2800,
      innerRate: 562.6,
      currency: 'RUB',
      role: 'LM',
    },
    {
      priceList: 'Janssen RU',
      title: 'Channel Manager',
      rate: 2800,
      innerRate: 460.3,
      currency: 'RUB',
      role: 'CH',
    },
    {
      priceList: 'Janssen RU',
      title: 'Medical Marketing Expert',
      rate: 3200,
      innerRate: 691.9,
      currency: 'RUB',
      role: 'MedAdv',
    },
    {
      priceList: 'Janssen RU',
      title: 'Project Manager',
      rate: 2800,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'CrtvPM',
    },
    {
      priceList: 'Janssen RU',
      title: 'Senior Graphic Designer',
      rate: 3200,
      innerRate: 670.9,
      currency: 'RUB',
      role: 'SDsgn',
    },
    {
      priceList: 'Janssen RU',
      title: 'Graphic Designer',
      rate: 2800,
      innerRate: 387.5,
      currency: 'RUB',
      role: 'Dsgnr',
    },
    {
      priceList: 'Janssen RU',
      title: 'Proofreader',
      rate: 2800,
      innerRate: 212.4,
      currency: 'RUB',
      role: 'PR/QA',
    },
    {
      priceList: 'Janssen RU',
      title: 'Project Manager',
      rate: 2800,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'PM',
    },
    {
      priceList: 'Janssen RU',
      title: 'Frontend Developer',
      rate: 3200,
      innerRate: 464.5,
      currency: 'RUB',
      role: 'SDvlpr',
    },
    {
      priceList: 'Janssen RU',
      title: 'HTML 5 Coder',
      rate: 2800,
      innerRate: 253.3,
      currency: 'RUB',
      role: 'Dvlpr',
    },
    {
      priceList: 'Janssen RU',
      title: 'Senior QA Engineer',
      rate: 3200,
      innerRate: 481.4,
      currency: 'RUB',
      role: 'QAMngr',
    },
    {
      priceList: 'Janssen RU',
      title: 'QA Engineer',
      rate: 2800,
      innerRate: 299,
      currency: 'RUB',
      role: 'QA',
    },
    {
      priceList: 'Janssen RU',
      title: 'Project Manager',
      rate: 2800,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'DplmMngr',
    },
    {
      priceList: 'Janssen RU',
      title: 'Content Deployment Engineer',
      rate: 2800,
      innerRate: 316.5,
      currency: 'RUB',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Janssen UA',
      title: 'Liaison Manager',
      rate: 900,
      innerRate: 247.8,
      currency: 'UAH',
      role: 'LM',
    },
    {
      priceList: 'Janssen UA',
      title: 'Channel Manager',
      rate: 900,
      innerRate: 202.7,
      currency: 'UAH',
      role: 'CH',
    },
    {
      priceList: 'Janssen UA',
      title: 'Medical Marketing Expert',
      rate: 995,
      innerRate: 304.8,
      currency: 'UAH',
      role: 'MedAdv',
    },
    {
      priceList: 'Janssen UA',
      title: 'Project Manager',
      rate: 900,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'CrtvPM',
    },
    {
      priceList: 'Janssen UA',
      title: 'Senior Graphic Designer',
      rate: 900,
      innerRate: 295.5,
      currency: 'UAH',
      role: 'SDsgn',
    },
    {
      priceList: 'Janssen UA',
      title: 'Graphic Designer',
      rate: 900,
      innerRate: 170.7,
      currency: 'UAH',
      role: 'Dsgnr',
    },
    {
      priceList: 'Janssen UA',
      title: 'Proofreader',
      rate: 900,
      innerRate: 93.5,
      currency: 'UAH',
      role: 'PR/QA',
    },
    {
      priceList: 'Janssen UA',
      title: 'Project Manager',
      rate: 900,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'PM',
    },
    {
      priceList: 'Janssen UA',
      title: 'Frontend Developer',
      rate: 1280,
      innerRate: 204.6,
      currency: 'UAH',
      role: 'SDvlpr',
    },
    {
      priceList: 'Janssen UA',
      title: 'HTML 5 Coder',
      rate: 900,
      innerRate: 111.6,
      currency: 'UAH',
      role: 'Dvlpr',
    },
    {
      priceList: 'Janssen UA',
      title: 'Senior QA Engineer',
      rate: 900,
      innerRate: 212,
      currency: 'UAH',
      role: 'QAMngr',
    },
    {
      priceList: 'Janssen UA',
      title: 'QA Engineer',
      rate: 900,
      innerRate: 131.7,
      currency: 'UAH',
      role: 'QA',
    },
    {
      priceList: 'Janssen UA',
      title: 'Project Manager',
      rate: 900,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'DplmMngr',
    },
    {
      priceList: 'Janssen UA',
      title: 'Content Deployment Engineer',
      rate: 900,
      innerRate: 139.4,
      currency: 'UAH',
      role: 'DplmEngnr',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Liaison Manager',
      rate: 900,
      innerRate: 247.8,
      currency: 'UAH',
      role: 'LM',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Channel Manager',
      rate: 900,
      innerRate: 202.7,
      currency: 'UAH',
      role: 'CH',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Medical Marketing Expert',
      rate: 995,
      innerRate: 304.8,
      currency: 'UAH',
      role: 'MedAdv',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Project Manager',
      rate: 900,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'CrtvPM',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Senior Graphic Designer',
      rate: 900,
      innerRate: 295.5,
      currency: 'UAH',
      role: 'SDsgn',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Graphic Designer',
      rate: 900,
      innerRate: 170.7,
      currency: 'UAH',
      role: 'Dsgnr',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Proofreader',
      rate: 900,
      innerRate: 93.5,
      currency: 'UAH',
      role: 'PR/QA',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Project Manager',
      rate: 900,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'PM',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Frontend Developer',
      rate: 900,
      innerRate: 204.6,
      currency: 'UAH',
      role: 'SDvlpr',
    },
    {
      priceList: 'SANOFI UA',
      title: 'HTML 5 Coder',
      rate: 900,
      innerRate: 111.6,
      currency: 'UAH',
      role: 'Dvlpr',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Senior QA Engineer',
      rate: 900,
      innerRate: 212,
      currency: 'UAH',
      role: 'QAMngr',
    },
    {
      priceList: 'SANOFI UA',
      title: 'QA Engineer',
      rate: 900,
      innerRate: 131.7,
      currency: 'UAH',
      role: 'QA',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Project Manager',
      rate: 900,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'DplmMngr',
    },
    {
      priceList: 'SANOFI UA',
      title: 'Content Deployment Engineer',
      rate: 900,
      innerRate: 139.4,
      currency: 'UAH',
      role: 'DplmEngnr',
    },
    {
      priceList: 'ACINO UA',
      title: 'Liaison Manager',
      rate: 810,
      innerRate: 247.8,
      currency: 'UAH',
      role: 'LM',
    },
    {
      priceList: 'ACINO UA',
      title: 'Channel Manager',
      rate: 810,
      innerRate: 202.7,
      currency: 'UAH',
      role: 'CH',
    },
    {
      priceList: 'ACINO UA',
      title: 'Medical Marketing Expert',
      rate: 810,
      innerRate: 304.8,
      currency: 'UAH',
      role: 'MedAdv',
    },
    {
      priceList: 'ACINO UA',
      title: 'Project Manager',
      rate: 855,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'CrtvPM',
    },
    {
      priceList: 'ACINO UA',
      title: 'Senior Graphic Designer',
      rate: 900,
      innerRate: 295.5,
      currency: 'UAH',
      role: 'SDsgn',
    },
    {
      priceList: 'ACINO UA',
      title: 'Graphic Designer',
      rate: 900,
      innerRate: 170.7,
      currency: 'UAH',
      role: 'Dsgnr',
    },
    {
      priceList: 'ACINO UA',
      title: 'Proofreader',
      rate: 810,
      innerRate: 93.5,
      currency: 'UAH',
      role: 'PR/QA',
    },
    {
      priceList: 'ACINO UA',
      title: 'Project Manager',
      rate: 855,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'PM',
    },
    {
      priceList: 'ACINO UA',
      title: 'Frontend Developer',
      rate: 1000,
      innerRate: 204.6,
      currency: 'UAH',
      role: 'SDvlpr',
    },
    {
      priceList: 'ACINO UA',
      title: 'HTML 5 Coder',
      rate: 900,
      innerRate: 111.6,
      currency: 'UAH',
      role: 'Dvlpr',
    },
    {
      priceList: 'ACINO UA',
      title: 'Senior QA Engineer',
      rate: 900,
      innerRate: 212,
      currency: 'UAH',
      role: 'QAMngr',
    },
    {
      priceList: 'ACINO UA',
      title: 'QA Engineer',
      rate: 900,
      innerRate: 131.7,
      currency: 'UAH',
      role: 'QA',
    },
    {
      priceList: 'ACINO UA',
      title: 'Project Manager',
      rate: 855,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'DplmMngr',
    },
    {
      priceList: 'ACINO UA',
      title: 'Content Deployment Engineer',
      rate: 900,
      innerRate: 139.4,
      currency: 'UAH',
      role: 'DplmEngnr',
    },
    {
      priceList: 'DrRed RU',
      title: 'Liaison Manager',
      rate: 2310,
      innerRate: 562.6,
      currency: 'RUB',
      role: 'LM',
    },
    {
      priceList: 'DrRed RU',
      title: 'Channel Manager',
      rate: 2310,
      innerRate: 460.3,
      currency: 'RUB',
      role: 'CH',
    },
    {
      priceList: 'DrRed RU',
      title: 'Medical Marketing Expert',
      rate: 2310,
      innerRate: 691.9,
      currency: 'RUB',
      role: 'MedAdv',
    },
    {
      priceList: 'DrRed RU',
      title: 'Project Manager',
      rate: 2310,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'CrtvPM',
    },
    {
      priceList: 'DrRed RU',
      title: 'Senior Graphic Designer',
      rate: 1925,
      innerRate: 670.9,
      currency: 'RUB',
      role: 'SDsgn',
    },
    {
      priceList: 'DrRed RU',
      title: 'Graphic Designer',
      rate: 1925,
      innerRate: 387.5,
      currency: 'RUB',
      role: 'Dsgnr',
    },
    {
      priceList: 'DrRed RU',
      title: 'Proofreader',
      rate: 1925,
      innerRate: 212.4,
      currency: 'RUB',
      role: 'PR/QA',
    },
    {
      priceList: 'DrRed RU',
      title: 'Project Manager',
      rate: 2310,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'PM',
    },
    {
      priceList: 'DrRed RU',
      title: 'Frontend Developer',
      rate: 2310,
      innerRate: 464.5,
      currency: 'RUB',
      role: 'SDvlpr',
    },
    {
      priceList: 'DrRed RU',
      title: 'HTML 5 Coder',
      rate: 2310,
      innerRate: 253.3,
      currency: 'RUB',
      role: 'Dvlpr',
    },
    {
      priceList: 'DrRed RU',
      title: 'Senior QA Engineer',
      rate: 2310,
      innerRate: 481.4,
      currency: 'RUB',
      role: 'QAMngr',
    },
    {
      priceList: 'DrRed RU',
      title: 'QA Engineer',
      rate: 2310,
      innerRate: 299,
      currency: 'RUB',
      role: 'QA',
    },
    {
      priceList: 'DrRed RU',
      title: 'Project Manager',
      rate: 2310,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'DplmMngr',
    },
    {
      priceList: 'DrRed RU',
      title: 'Content Deployment Engineer',
      rate: 2310,
      innerRate: 316.5,
      currency: 'RUB',
      role: 'DplmEngnr',
    },
    {
      priceList: 'AZ KZ',
      title: 'Liaison Manager',
      rate: 7950,
      innerRate: 4020.5,
      currency: 'KZT',
      role: 'LM',
    },
    {
      priceList: 'AZ KZ',
      title: 'Channel Manager',
      rate: 7950,
      innerRate: 3289.5,
      currency: 'KZT',
      role: 'CH',
    },
    {
      priceList: 'AZ KZ',
      title: 'Medical Marketing Expert',
      rate: 7950,
      innerRate: 4945,
      currency: 'KZT',
      role: 'MedAdv',
    },
    {
      priceList: 'AZ KZ',
      title: 'Project Manager',
      rate: 7950,
      innerRate: 4807.4,
      currency: 'KZT',
      role: 'CrtvPM',
    },
    {
      priceList: 'AZ KZ',
      title: 'Senior Graphic Designer',
      rate: 7950,
      innerRate: 4794.5,
      currency: 'KZT',
      role: 'SDsgn',
    },
    {
      priceList: 'AZ KZ',
      title: 'Graphic Designer',
      rate: 7950,
      innerRate: 2769.2,
      currency: 'KZT',
      role: 'Dsgnr',
    },
    {
      priceList: 'AZ KZ',
      title: 'Proofreader',
      rate: 7950,
      innerRate: 1517.9,
      currency: 'KZT',
      role: 'PR/QA',
    },
    {
      priceList: 'AZ KZ',
      title: 'Project Manager',
      rate: 7950,
      innerRate: 4807.4,
      currency: 'KZT',
      role: 'PM',
    },
    {
      priceList: 'AZ KZ',
      title: 'Frontend Developer',
      rate: 7950,
      innerRate: 3319.6,
      currency: 'KZT',
      role: 'SDvlpr',
    },
    {
      priceList: 'AZ KZ',
      title: 'HTML 5 Coder',
      rate: 7950,
      innerRate: 1810.3,
      currency: 'KZT',
      role: 'Dvlpr',
    },
    {
      priceList: 'AZ KZ',
      title: 'Senior QA Engineer',
      rate: 7950,
      innerRate: 3440,
      currency: 'KZT',
      role: 'QAMngr',
    },
    {
      priceList: 'AZ KZ',
      title: 'QA Engineer',
      rate: 7950,
      innerRate: 2137.1,
      currency: 'KZT',
      role: 'QA',
    },
    {
      priceList: 'AZ KZ',
      title: 'Project Manager',
      rate: 7950,
      innerRate: 4807.4,
      currency: 'KZT',
      role: 'DplmMngr',
    },
    {
      priceList: 'AZ KZ',
      title: 'Content Deployment Engineer',
      rate: 7950,
      innerRate: 2261.8,
      currency: 'KZT',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Abbott GL',
      title: 'Liaison Manager',
      rate: 42,
      innerRate: 9.4,
      currency: 'USD',
      role: 'LM',
    },
    {
      priceList: 'Abbott GL',
      title: 'Channel Manager',
      rate: 40,
      innerRate: 7.7,
      currency: 'USD',
      role: 'CH',
    },
    {
      priceList: 'Abbott GL',
      title: 'Medical Marketing Expert',
      rate: 60,
      innerRate: 11.5,
      currency: 'USD',
      role: 'MedAdv',
    },
    {
      priceList: 'Abbott GL',
      title: 'Project Manager',
      rate: 40,
      innerRate: 11.2,
      currency: 'USD',
      role: 'CrtvPM',
    },
    {
      priceList: 'Abbott GL',
      title: 'Senior Graphic Designer',
      rate: 48,
      innerRate: 11.2,
      currency: 'USD',
      role: 'SDsgn',
    },
    {
      priceList: 'Abbott GL',
      title: 'Graphic Designer',
      rate: 36,
      innerRate: 6.4,
      currency: 'USD',
      role: 'Dsgnr',
    },
    {
      priceList: 'Abbott GL',
      title: 'Proofreader',
      rate: 34,
      innerRate: 3.5,
      currency: 'USD',
      role: 'PR/QA',
    },
    {
      priceList: 'Abbott GL',
      title: 'Project Manager',
      rate: 40,
      innerRate: 11.2,
      currency: 'USD',
      role: 'PM',
    },
    {
      priceList: 'Abbott GL',
      title: 'Frontend Developer',
      rate: 42,
      innerRate: 7.7,
      currency: 'USD',
      role: 'SDvlpr',
    },
    {
      priceList: 'Abbott GL',
      title: 'HTML 5 Coder',
      rate: 36,
      innerRate: 4.2,
      currency: 'USD',
      role: 'Dvlpr',
    },
    {
      priceList: 'Abbott GL',
      title: 'Senior QA Engineer',
      rate: 42,
      innerRate: 8,
      currency: 'USD',
      role: 'QAMngr',
    },
    {
      priceList: 'Abbott GL',
      title: 'QA Engineer',
      rate: 34,
      innerRate: 5,
      currency: 'USD',
      role: 'QA',
    },
    {
      priceList: 'Abbott GL',
      title: 'Project Manager',
      rate: 40,
      innerRate: 11.2,
      currency: 'USD',
      role: 'DplmMngr',
    },
    {
      priceList: 'Abbott GL',
      title: 'Content Deployment Engineer',
      rate: 35,
      innerRate: 5.3,
      currency: 'USD',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Global USD PL',
      title: 'Liaison Manager',
      rate: 45,
      innerRate: 9.4,
      currency: 'USD',
      role: 'LM',
    },
    {
      priceList: 'Global USD PL',
      title: 'Channel Manager',
      rate: 50,
      innerRate: 7.7,
      currency: 'USD',
      role: 'CH',
    },
    {
      priceList: 'Global USD PL',
      title: 'Medical Marketing Expert',
      rate: 60,
      innerRate: 11.5,
      currency: 'USD',
      role: 'MedAdv',
    },
    {
      priceList: 'Global USD PL',
      title: 'Project Manager',
      rate: 45,
      innerRate: 11.2,
      currency: 'USD',
      role: 'CrtvPM',
    },
    {
      priceList: 'Global USD PL',
      title: 'Senior Graphic Designer',
      rate: 50,
      innerRate: 11.2,
      currency: 'USD',
      role: 'SDsgn',
    },
    {
      priceList: 'Global USD PL',
      title: 'Graphic Designer',
      rate: 40,
      innerRate: 6.4,
      currency: 'USD',
      role: 'Dsgnr',
    },
    {
      priceList: 'Global USD PL',
      title: 'Proofreader',
      rate: 35,
      innerRate: 3.5,
      currency: 'USD',
      role: 'PR/QA',
    },
    {
      priceList: 'Global USD PL',
      title: 'Project Manager',
      rate: 45,
      innerRate: 11.2,
      currency: 'USD',
      role: 'PM',
    },
    {
      priceList: 'Global USD PL',
      title: 'Frontend Developer',
      rate: 45,
      innerRate: 7.7,
      currency: 'USD',
      role: 'SDvlpr',
    },
    {
      priceList: 'Global USD PL',
      title: 'HTML 5 Coder',
      rate: 40,
      innerRate: 4.2,
      currency: 'USD',
      role: 'Dvlpr',
    },
    {
      priceList: 'Global USD PL',
      title: 'Senior QA Engineer',
      rate: 45,
      innerRate: 8,
      currency: 'USD',
      role: 'QAMngr',
    },
    {
      priceList: 'Global USD PL',
      title: 'QA Engineer',
      rate: 35,
      innerRate: 5,
      currency: 'USD',
      role: 'QA',
    },
    {
      priceList: 'Global USD PL',
      title: 'Project Manager',
      rate: 45,
      innerRate: 11.2,
      currency: 'USD',
      role: 'DplmMngr',
    },
    {
      priceList: 'Global USD PL',
      title: 'Content Deployment Engineer',
      rate: 45,
      innerRate: 5.3,
      currency: 'USD',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Liaison Manager',
      rate: 35,
      innerRate: 7.9,
      currency: 'EUR',
      role: 'LM',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Channel Manager',
      rate: 35,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'CH',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Medical Marketing Expert',
      rate: 50,
      innerRate: 9.7,
      currency: 'EUR',
      role: 'MedAdv',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Project Manager',
      rate: 35,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'CrtvPM',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Senior Graphic Designer',
      rate: 40,
      innerRate: 9.4,
      currency: 'EUR',
      role: 'SDsgn',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Graphic Designer',
      rate: 30,
      innerRate: 5.5,
      currency: 'EUR',
      role: 'Dsgnr',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Proofreader',
      rate: 28,
      innerRate: 3,
      currency: 'EUR',
      role: 'PR/QA',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Project Manager',
      rate: 35,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'PM',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Frontend Developer',
      rate: 35,
      innerRate: 6.5,
      currency: 'EUR',
      role: 'SDvlpr',
    },
    {
      priceList: 'Global EUR PL',
      title: 'HTML 5 Coder',
      rate: 30,
      innerRate: 3.6,
      currency: 'EUR',
      role: 'Dvlpr',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Senior QA Engineer',
      rate: 35,
      innerRate: 6.8,
      currency: 'EUR',
      role: 'QAMngr',
    },
    {
      priceList: 'Global EUR PL',
      title: 'QA Engineer',
      rate: 28,
      innerRate: 4.2,
      currency: 'EUR',
      role: 'QA',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Project Manager',
      rate: 35,
      innerRate: 9.5,
      currency: 'EUR',
      role: 'DplmMngr',
    },
    {
      priceList: 'Global EUR PL',
      title: 'Content Deployment Engineer',
      rate: 35,
      innerRate: 4.5,
      currency: 'EUR',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Liaison Manager',
      rate: 1095,
      innerRate: 247.8,
      currency: 'UAH',
      role: 'LM',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Channel Manager',
      rate: 1095,
      innerRate: 202.7,
      currency: 'UAH',
      role: 'CH',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Medical Marketing Expert',
      rate: 1564,
      innerRate: 304.8,
      currency: 'UAH',
      role: 'MedAdv',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Project Manager',
      rate: 1095,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'CrtvPM',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Senior Graphic Designer',
      rate: 1251,
      innerRate: 295.5,
      currency: 'UAH',
      role: 'SDsgn',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Graphic Designer',
      rate: 939,
      innerRate: 170.7,
      currency: 'UAH',
      role: 'Dsgnr',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Proofreader',
      rate: 876,
      innerRate: 93.5,
      currency: 'UAH',
      role: 'PR/QA',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Project Manager',
      rate: 1095,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'PM',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Frontend Developer',
      rate: 1095,
      innerRate: 204.6,
      currency: 'UAH',
      role: 'SDvlpr',
    },
    {
      priceList: 'Global UAH PL',
      title: 'HTML 5 Coder',
      rate: 939,
      innerRate: 111.6,
      currency: 'UAH',
      role: 'Dvlpr',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Senior QA Engineer',
      rate: 1095,
      innerRate: 212,
      currency: 'UAH',
      role: 'QAMngr',
    },
    {
      priceList: 'Global UAH PL',
      title: 'QA Engineer',
      rate: 876,
      innerRate: 131.7,
      currency: 'UAH',
      role: 'QA',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Project Manager',
      rate: 1095,
      innerRate: 296.3,
      currency: 'UAH',
      role: 'DplmMngr',
    },
    {
      priceList: 'Global UAH PL',
      title: 'Content Deployment Engineer',
      rate: 1095,
      innerRate: 139.4,
      currency: 'UAH',
      role: 'DplmEngnr',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Liaison Manager',
      rate: 2933,
      innerRate: 562.6,
      currency: 'RUB',
      role: 'LM',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Channel Manager',
      rate: 2933,
      innerRate: 460.3,
      currency: 'RUB',
      role: 'CH',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Medical Marketing Expert',
      rate: 4189,
      innerRate: 691.9,
      currency: 'RUB',
      role: 'MedAdv',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Project Manager',
      rate: 2933,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'CrtvPM',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Senior Graphic Designer',
      rate: 3352,
      innerRate: 670.9,
      currency: 'RUB',
      role: 'SDsgn',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Graphic Designer',
      rate: 2514,
      innerRate: 387.5,
      currency: 'RUB',
      role: 'Dsgnr',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Proofreader',
      rate: 2346,
      innerRate: 212.4,
      currency: 'RUB',
      role: 'PR/QA',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Project Manager',
      rate: 2933,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'PM',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Frontend Developer',
      rate: 2933,
      innerRate: 464.5,
      currency: 'RUB',
      role: 'SDvlpr',
    },
    {
      priceList: 'Global RUB PL',
      title: 'HTML 5 Coder',
      rate: 2514,
      innerRate: 253.3,
      currency: 'RUB',
      role: 'Dvlpr',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Senior QA Engineer',
      rate: 2933,
      innerRate: 481.4,
      currency: 'RUB',
      role: 'QAMngr',
    },
    {
      priceList: 'Global RUB PL',
      title: 'QA Engineer',
      rate: 2346,
      innerRate: 299,
      currency: 'RUB',
      role: 'QA',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Project Manager',
      rate: 2933,
      innerRate: 672.7,
      currency: 'RUB',
      role: 'DplmMngr',
    },
    {
      priceList: 'Global RUB PL',
      title: 'Content Deployment Engineer',
      rate: 2933,
      innerRate: 316.5,
      currency: 'RUB',
      role: 'DplmEngnr',
    },
  ];
}
