const currencySignMap = {
  USD: '$',
  'US Dollar': '$',
  EUR: '€',
  Euro: '€',
  RUB: '₽',
  Ruble: '₽',
  UAH: '₴',
  Hryvnia: '₴',
  KZT: '₸',
};

export default function currencySign(key: string) {
  // @ts-ignore
  return currencySignMap[key];
}
