import { CalculatorInputData } from '@/types/input/CalculatorInputData';
import { NumberOfSlides } from '@/types/slides/NumberOfSlides';
import { InputCheckboxes } from '@/types/input/InputCheckboxes';

export default class InputComparator {
  static isEqual(inset: CalculatorInputData, backupString: string) {
    const backup = JSON.parse(backupString) as CalculatorInputData;
    let isSame = true;

    // number typed fields can be strings coming from HTML input fields.
    ['reuseRatioPercentage', 'discountPercentage'].forEach((key) => {
      // @ts-ignore
      if (parseInt(inset[key], 10) !== parseInt(backup[key], 10)) {
        isSame = false;
      }
    });

    ['name', 'type', 'selectedAssetType', 'selectedJobType', 'selectedTargetSystem',
      'selectedScopingComplexity', 'selectedPriceList', 'jiraEpic',
      'currentApproverId'].forEach((key) => {
      if (inset[key as keyof CalculatorInputData] !== backup[key as keyof CalculatorInputData]) {
        isSame = false;
      }
    });

    Object.keys(inset.checkboxes).forEach((key) => {
      if (inset.checkboxes[key as keyof InputCheckboxes]
        !== backup.checkboxes[key as keyof InputCheckboxes]) {
        isSame = false;
      }
    });

    // number typed fields can be strings, that are coming from HTML input fields.
    Object.keys(inset.numberOfSlides).forEach((level) => {
      Object.keys(inset.numberOfSlides[level as keyof NumberOfSlides]).forEach((slideType) => {
        // @ts-ignore
        if (parseInt(inset.numberOfSlides[level][slideType], 10)
          // @ts-ignore
          !== parseInt(backup.numberOfSlides[level][slideType], 10)) {
          isSame = false;
        }
      });
    });

    if (inset.jobData.services !== backup.jobData.services) {
      isSame = false;
    }

    if (inset.calculationType === 'standard') {
      if (inset.jobData.jobs !== backup.jobData.jobs) {
        isSame = false;
      }
    }

    return isSame;
  }
}
