
































import Vue from 'vue';

export default Vue.extend({
  name: 'UnsavedChangesWarningPopup',
  props: {
    showUnsavedChangesWarning: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  methods: {
    close() {
      this.$emit('update:showUnsavedChangesWarning', false);
    },
    discard() {
      this.$emit('discardChanges');
    },
  },
});
