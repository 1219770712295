
































import Vue from 'vue';

export default Vue.extend({
  name: 'SavingSuccessPopup',
  props: {
    showSuccessDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  methods: {
    close() {
      this.$emit('update:showSuccessDialog', false);
    },
  },
});
