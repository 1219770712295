import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import CalculatorWrapper from '@/components/CalculatorWrapper.vue';
import List from '@/views/List.vue';
import NotFound from '@/views/NotFound.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'List',
    component: List,
  },
  {
    path: '/new-item',
    name: 'NewItem',
    props: true,
    component: CalculatorWrapper,
  },
  {
    path: '/new-custom-item',
    name: 'NewCustomItem',
    props: true,
    component: CalculatorWrapper,
  },
  {
    path: '/new-flat-rate-item',
    name: 'NewFlatRateItem',
    props: true,
    component: CalculatorWrapper,
  },
  {
    path: '/item/:id',
    name: 'CalculatorPage',
    props: true,
    component: CalculatorWrapper,
  },
  { path: '*', component: NotFound },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
