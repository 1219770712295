/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "amz",
            "endpoint": "https://k8b0193b02.execute-api.eu-central-1.amazonaws.com/master",
            "region": "eu-central-1"
        },
        {
            "name": "jiraapi",
            "endpoint": "https://yga1dg9kig.execute-api.eu-central-1.amazonaws.com/master",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vekkxekaxfg43douwxxvrgl7pa.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_BdDMxpRF5",
    "aws_user_pools_web_client_id": "3p8a2t3rul5pcci27hf2a4m1cc",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
