






























































import Vue from 'vue';
import { CalculatorInputData } from '@/types/input/CalculatorInputData';
import Utils from '@/helpers/utils';
import { Job } from '@/types/jobs/Job';
import ReferenceBook from '@/data-sources/ReferenceBook';
import { InputCheckboxes } from '@/types/input/InputCheckboxes';

export default Vue.extend({
  name: 'ResultSection',
  props: {
    inset: {
      type: Object as () => CalculatorInputData,
      required: true,
    },
    jobs: {
      type: Array as () => Array<Job>,
      required: true,
    },
    totalSlidesAmount: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    Utils,
  }),
  computed: {
    slaLevel() {
      return ReferenceBook.getSlaLevel(this.inset.selectedAssetType, this.totalSlidesAmount);
    },
    absoluteTotalHoursPerComponent() {
      return Utils.roundDecimal(
        this.jobs.reduce((acc: number, job: Job) => acc + job.totalHoursPerComponent, 0.0),
      );
    },
    absoluteHoursPerAsset() {
      return Utils.roundDecimal(
        this.jobs.reduce((acc: number, job: Job) => acc + job.hoursPerAsset, 0.0),
      );
    },
    absoluteTotalHours() {
      return Utils.getAbsoluteTotalHours(this.jobs);
    },
    absoluteCostPerAsset() {
      return Utils.getAbsoluteCostPerAsset(this.jobs);
    },
    absoluteSlaDays() {
      return Utils.getAbsoluteSlaDays(this.jobs, this.inset);
    },
    absGeneralWorkPercentage() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const temp = this.absoluteHoursPerAsset / this.absoluteTotalHours;
      return `${Utils.roundDecimal(temp * 100)}%`;
    },
    absoluteTargetSystemValues() {
      const value = {
        simple: 0, medium: 0, complex: 0,
      };
      Object.keys(value).forEach((level) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        value[level] = Utils.roundDecimal(
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          this.jobs.reduce((acc: number, job: Job) => acc + job[level as keyof Job], 0.0),
        );
      });
      return value;
    },
    absoluteJobTypeValues() {
      const value = {
        simple: 0, medium: 0, complex: 0,
      };
      Object.keys(value).forEach((level) => {
        // @ts-ignore
        value[level] = this.jobs.reduce((acc: number, job: Job) => {
          // @ts-ignore
          if (Object.keys(this.inset.checkboxes).includes(job.jobKey)
            // @ts-ignore
            && !this.inset.checkboxes[job.jobKey as keyof InputCheckboxes]) {
            return acc;
          }
          // @ts-ignore
          return acc + job[level as keyof Job];
        }, 0.0);
        // @ts-ignore
        value[level] *= Utils.slidesSum(level, this.inset.numberOfSlides);
        // @ts-ignore
        value[level] = Utils.roundDecimal(value[level]);
      });
      return value;
    },
  },
});
