



























import Vue from 'vue';
import Parsers from '@/helpers/parsers';

export default Vue.extend({
  name: 'ErrorPopup',
  props: {
    showErrorDialog: {
      type: Boolean,
      default: false,
    },
    errorObject: {
      type: Object,
    },
    shouldGoHome: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    Parsers,
  }),
  methods: {
    close() {
      if (this.shouldGoHome) {
        this.$router.push({ name: 'List' });
      } else {
        this.$emit('update:showErrorDialog', false);
      }
    },
  },
});
