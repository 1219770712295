import { JobWorker } from '@/types/jobs/JobWorker';
import { NumberOfSlides } from '@/types/slides/NumberOfSlides';
import ReferenceBook from '@/data-sources/ReferenceBook';
import { ScopingComplexity } from '@/types/ScopingComplexity';
import { Job } from '@/types/jobs/Job';
import { CalculatorInputData } from '@/types/input/CalculatorInputData';
import { InputCheckboxes } from '@/types/input/InputCheckboxes';
import Norms from '@/data-sources/Norms';
import { LevelCostValues } from '@/types/jobs/LevelCostValues';
import { LevelCost } from '@/types/jobs/LevelCost';

export default class Utils {
  static totalLevelSum(level: keyof LevelCost, field: keyof LevelCostValues, jobArray: Array<Job>) {
    const sum = jobArray.reduce((accumulator, current) => {
      accumulator += current.levelCosts[level][field]!;
      return accumulator;
    }, 0.0);
    return this.roundDecimal(sum);
  }

  static columnSum(listArray: Array<JobWorker>, columnKey: keyof JobWorker) {
    const sum = listArray.reduce((accumulator, current) => {
      if (typeof current[columnKey] === 'number') {
        accumulator += (current[columnKey] as number);
      }
      return accumulator;
    }, 0.0);
    return this.roundDecimal(sum);
  }

  static slidesSum(level: string, slides: NumberOfSlides) {
    return Object.values(slides[level as keyof NumberOfSlides])
      .reduce((acc: number, item) => acc + parseInt(item.toString(), 10), 0);
  }

  static totalSlidesSum(slides: NumberOfSlides): number {
    let sum = 0;
    Object.keys(slides).forEach((key) => {
      sum += this.slidesSum(key, slides);
    });
    return sum;
  }

  static sumProductNormsSlides(job: Job, numberOfSlides: NumberOfSlides) {
    if (job.simple === 0 && job.medium === 0 && job.complex === 0) {
      return this.slidesSum('simple', numberOfSlides)
        + this.slidesSum('medium', numberOfSlides)
        + this.slidesSum('complex', numberOfSlides);
    }
    return job.simple * this.slidesSum('simple', numberOfSlides)
      + job.medium * this.slidesSum('medium', numberOfSlides)
      + job.complex * this.slidesSum('complex', numberOfSlides);
  }

  static roundDecimal(num: number, position = 1) {
    const factor = 10 * position;
    return Math.round((num + Number.EPSILON) * factor) / factor;
  }

  static parsePercent(percent: string) {
    return parseFloat(percent) / 100.0;
  }

  static getScopingComplexityValue(scopingComplexity: string) {
    return ReferenceBook.getScopingComplexityValue(scopingComplexity as keyof ScopingComplexity);
  }

  static getReuseRatioIndex(selectedJobType: string, reuseRatioPercentage: number) {
    if (!(selectedJobType === 'Update')) {
      return (1.0 - reuseRatioPercentage / 100);
    }
    return 1;
  }

  static getTotalHoursPerComponent(worker: JobWorker, jobKey: string, inset: CalculatorInputData) {
    let hours = 0;
    if (Object.keys(inset.checkboxes).includes(jobKey)
      && !inset.checkboxes[jobKey as keyof InputCheckboxes]) {
      return hours;
    }
    ['simple', 'medium', 'complex'].forEach((slideLevel) => {
      if (typeof worker[slideLevel as keyof JobWorker] === 'number') {
        hours += (worker[slideLevel as keyof JobWorker] as number)
          * this.slidesSum(slideLevel, inset.numberOfSlides);
      }
    });
    if (jobKey === 'Scoping') {
      hours *= this.getScopingComplexityValue(inset.selectedScopingComplexity);
      if (!inset.checkboxes.Design) {
        hours *= 0.5;
      }
    }
    if (['Build', 'Test', 'Deploy'].includes(jobKey)) {
      hours *= ReferenceBook.getTargetSystemIndex(inset.selectedAssetType,
        inset.selectedTargetSystem, jobKey);
    }
    hours *= this.getReuseRatioIndex(inset.selectedJobType, inset.reuseRatioPercentage);
    return this.roundDecimal(hours);
  }

  static getHoursPerAsset(worker: JobWorker, job: Job, inset: CalculatorInputData,
    sumProductNormsSlides: number) {
    let hours = 0;
    if (Object.keys(inset.checkboxes).includes(job.jobKey)
      && !inset.checkboxes[job.jobKey as keyof InputCheckboxes]) {
      return hours;
    }
    const generalWork = this.parsePercent(worker.generalWorkPercentage);
    if (job.jobKey === 'Scoping') {
      hours = generalWork;
      // The following part of formula has no use cases, because F21 >=1 or is 0. Skipping it now:
      // F21*($L$24*$H$13+$L$29+$L$33+$L$36*$H$15)*VLOOKUP($F$2,справочники!$Y$2:$Z$6,2,0)
    }
    if (job.jobKey === 'Design') {
      hours = generalWork;
      if (generalWork < 1) {
        if (worker.key === 'CrtvPM') { // special formula for CrtvPM
          const special = job.jobWorkers.find((jobWorker) => jobWorker.key === 'SDsgn');
          hours *= (sumProductNormsSlides + this.parsePercent(special!.generalWorkPercentage));
        } else {
          hours *= sumProductNormsSlides;
        }
      }
    }
    if (job.jobKey === 'Build') {
      hours = generalWork;
      if (generalWork < 1) {
        if (worker.key === 'PM') { // special formula
          const special = job.jobWorkers.find((jobWorker) => jobWorker.key === 'SDvlpr');
          hours *= (sumProductNormsSlides + this.parsePercent(special!.generalWorkPercentage));
        } else {
          hours *= sumProductNormsSlides;
        }
        hours *= ReferenceBook.getTargetSystemIndex(inset.selectedAssetType,
          inset.selectedTargetSystem, job.jobKey);
      }
    }
    if (job.jobKey === 'Test') {
      hours = generalWork;
      if (generalWork < 1) {
        if (worker.key === 'QAMngr') { // special formula
          const special = job.jobWorkers.find((jobWorker) => jobWorker.key === 'QA');
          hours *= (sumProductNormsSlides + this.parsePercent(special!.generalWorkPercentage));
        } else {
          hours *= sumProductNormsSlides;
        }
        hours *= ReferenceBook.getTargetSystemIndex(inset.selectedAssetType,
          inset.selectedTargetSystem, job.jobKey);
      }
    }
    if (job.jobKey === 'Deploy') {
      hours = 1; // change from 0 to 1 to multiply
      if (worker.key === 'DplmMngr') {
        hours = 0;
      } else {
        hours *= ReferenceBook.getTargetSystemIndex(inset.selectedAssetType,
          inset.selectedTargetSystem, job.jobKey);
        const totalSlidesSum = this.totalSlidesSum(inset.numberOfSlides);
        if (totalSlidesSum <= ReferenceBook.maxSlidesLevels.level1.value) {
          hours *= ReferenceBook.maxSlidesLevels.level1.index;
        } else if (totalSlidesSum <= ReferenceBook.maxSlidesLevels.level2.value) {
          hours *= ReferenceBook.maxSlidesLevels.level2.index;
        } else {
          hours *= ReferenceBook.maxSlidesLevels.level3.index;
        }
      }
    }
    if (job.jobKey === 'Campaign_Execution') {
      hours = 1; // change from 0 to 1 to multiply
      if (worker.key === 'DplmMngr') {
        hours = 0;
      } else if (inset.selectedAssetType === 'Broadcast E-Mail') {
        // @ts-ignore
        hours *= ReferenceBook.emailTargetSystemIndex[inset.selectedTargetSystem].hours;
      } else {
        hours = 0;
      }
    }
    hours *= this.getReuseRatioIndex(inset.selectedJobType, inset.reuseRatioPercentage);
    return this.roundDecimal(hours);
  }

  static getTotalHoursField(worker: JobWorker, totalSlidesAmount: number): number {
    if (totalSlidesAmount === 0) {
      return 0.0;
    }
    return this.roundDecimal(worker.totalHoursPerComponent + worker.hoursPerAsset);
  }

  static getAbsoluteTotalHours(structure: Array<Job>) {
    return this.roundDecimal(
      structure.reduce((acc: number, job: Job) => acc + job.totalHours, 0.0),
    );
  }

  static setTotalHoursPercentages(structure: Array<Job>) {
    const absTotalHours = this.getAbsoluteTotalHours(structure);
    structure.forEach((job: Job) => {
      // eslint-disable-next-line no-mixed-operators
      job.totalHoursPercentage = this.roundDecimal(job.totalHours / absTotalHours * 100);
    });
  }

  static getCostPerAsset(worker: JobWorker, discountPercentage: number) {
    return worker.rate * worker.totalHours * (1 - discountPercentage / 100);
  }

  static getAbsoluteCostPerAsset(structure: Array<Job>) {
    return structure.reduce((acc: number, job: Job) => acc + job.costPerAsset, 0.0);
  }

  static setCostPerAssetPercentage(structure: Array<Job>) {
    const absCostPerAsset = this.getAbsoluteCostPerAsset(structure);
    structure.forEach((job: Job) => {
      // eslint-disable-next-line no-mixed-operators
      job.costPerAssetPercentage = this.roundDecimal(job.costPerAsset / absCostPerAsset * 100);
    });
  }

  static getSlaDays(worker: JobWorker, inset: CalculatorInputData) {
    const absNumberOfSlides = this.totalSlidesSum(inset.numberOfSlides);
    let slaLevel = ReferenceBook.getSlaLevel(inset.selectedAssetType, absNumberOfSlides);
    // use 'Complex SLA' value for 'Custom SLA' as it is done in Excel calculator
    if (slaLevel === 'Custom SLA') {
      slaLevel = 'Complex SLA';
    }
    const normsLine = Norms.getNormsLine(inset.selectedAssetType, inset.selectedJobType, slaLevel);
    // @ts-ignore
    const normsValue = normsLine![worker.normsKey];
    if (typeof normsValue === 'number' && worker.totalHours === 0) {
      return 0;
    }
    return normsValue;
  }

  static getAbsoluteSlaDays(structure: Array<Job>, inset: CalculatorInputData) {
    const absNumberOfSlides = this.totalSlidesSum(inset.numberOfSlides);
    const slaLevel = ReferenceBook.getSlaLevel(inset.selectedAssetType, absNumberOfSlides);
    const result = `${this.roundDecimal(
      structure.reduce((acc: number, job: Job) => acc + job.slaDays, 0.0),
    )} days`;
    if (slaLevel === 'Custom SLA') {
      return `TBD. Above ${result}`;
    }
    return result;
  }
}
