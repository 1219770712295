


























import Vue from 'vue';

export default Vue.extend({
  name: 'ValidationErrorPopup',
  props: {
    showValidationErrorDialog: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
  },
  data: () => ({
  }),
  methods: {
    close() {
      this.$emit('update:showValidationErrorDialog', false);
    },
  },
});
