var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-5"},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.priceList,"label":"Price list"},model:{value:(_vm.filters.selectedPriceList),callback:function ($$v) {_vm.$set(_vm.filters, "selectedPriceList", $$v)},expression:"filters.selectedPriceList"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.assetTypeList,"label":"Asset type"},model:{value:(_vm.filters.selectedAssetType),callback:function ($$v) {_vm.$set(_vm.filters, "selectedAssetType", $$v)},expression:"filters.selectedAssetType"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.jobTypeList,"label":"Job type"},model:{value:(_vm.filters.selectedJobType),callback:function ($$v) {_vm.$set(_vm.filters, "selectedJobType", $$v)},expression:"filters.selectedJobType"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.usersList,"label":"Created By","menu-props":"eager"},model:{value:(_vm.filters.createdBy),callback:function ($$v) {_vm.$set(_vm.filters, "createdBy", $$v)},expression:"filters.createdBy"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.calculationTypeList,"label":"Calculation type"},model:{value:(_vm.filters.calculationType),callback:function ($$v) {_vm.$set(_vm.filters, "calculationType", $$v)},expression:"filters.calculationType"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"pb-2"},[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":_vm.clearFilters}},[_vm._v("Clear All Filters")])],1)])],1),_c('v-data-table',{staticClass:"elevation-1 pt-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.displayItems,"loading":_vm.isLoading,"loading-text":"Loading... Please wait","footer-props":{
      'items-per-page-options': [-1]
    },"items-per-page":-1,"hide-default-footer":"","search":_vm.frontEndSearch,"sort-by":_vm.sort.sortBy,"sort-desc":_vm.sort.sortDesc},on:{"click:row":_vm.rowClickHandler,"update:sortBy":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleString()))])]}},{key:"item.updatedAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.updatedAt).toLocaleString()))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Saved items:")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-5",attrs:{"label":"Search..."},model:{value:(_vm.frontEndSearch),callback:function ($$v) {_vm.frontEndSearch=$$v},expression:"frontEndSearch"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading,"fab":"","x-small":""},on:{"click":function($event){_vm.frontEndSearch = ''}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('v-btn',{on:{"click":function($event){_vm.showCreateNewDialog = true}}},[_vm._v("Create new")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editClickHandler(item.id)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openDeleteConfirmationDialog(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showCreateNewDialog = true}}},[_vm._v(" Create new calculation ")])]},proxy:true}])}),_c('ErrorPopup',{attrs:{"showErrorDialog":_vm.errorData.show,"errorObject":_vm.errorData.errorObject},on:{"update:showErrorDialog":function($event){return _vm.$set(_vm.errorData, "show", $event)},"update:show-error-dialog":function($event){return _vm.$set(_vm.errorData, "show", $event)}}}),_c('CreateNewPopup',{attrs:{"showCreateNewDialog":_vm.showCreateNewDialog},on:{"update:showCreateNewDialog":function($event){_vm.showCreateNewDialog=$event},"update:show-create-new-dialog":function($event){_vm.showCreateNewDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }