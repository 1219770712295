import CurrencyExchange from '@/data-sources/CurrencyExchange';
import Utils from '@/helpers/utils';
// @ts-ignore
import _ from 'lodash';
import flatRates from '@/data-sources/FlatRates.json';
import { FlatRate } from '@/types/rates/FlatRate';

export default class FlatRateRateCard {
  static getAssetTypeNames() {
    const names: string[] = [];
    this.data.forEach((item) => {
      if (!names.includes(item['Content production (Asset type)'])) {
        names.push(item['Content production (Asset type)']);
      }
    });
    return names.sort();
  }

  static getTemplateList(selectedAssetType: string) {
    const names: string[] = [];
    this.getDataByAssetType(selectedAssetType).forEach((item) => {
      if (item.Template && !names.includes(item.Template)) {
        names.push(item.Template);
      }
    });
    return names;
  }

  static getPSSList(
    selectedAssetType: string,
    template: string,
  ) {
    const names: string[] = [];
    this.getDataByAssetType(selectedAssetType).forEach((item) => {
      if (item.PSS && !names.includes(item.PSS) && item.Template === template) {
        names.push(item.PSS);
      }
    });
    return _.orderBy(names);
  }

  static getComplexityLevelList(
    selectedAssetType: string,
    template: string,
    pss: string,
  ) {
    const names: string[] = [];
    this.getDataByAssetType(selectedAssetType).forEach((item) => {
      if (
        item['Complexity level']
        && !names.includes(item['Complexity level'])
        && item.Template === template
        && item.PSS === pss
      ) {
        names.push(item['Complexity level']);
      }
    });
    return names;
  }

  static getTypeList(
    selectedAssetType: string,
    template: string,
    pss: string,
  ) {
    const allTypes = ['New', 'Update', 'Reuse'] as (keyof FlatRate)[];

    const types: string[] = [];

    this.getDataByAssetType(selectedAssetType).forEach((item) => {
      if (item.Template === template && item.PSS === pss) {
        allTypes.forEach(
          (type) => {
            if (!!item[type] && !types.includes(type)) {
              types.push(type);
            }
          },
        );
      }
    });

    return types;

    /*     const anyItem = this.data.find(
      (item) => item['Content production (Asset type)'] === selectedAssetType,
    );
    if (!anyItem!.Template) {
      return [];
    } */
    return ['New', 'Update', 'Reuse'];
  }

  static getPriceList() {
    return this.priceListData.map((item) => item.priceList);
  }

  static getCurrency(selectedPriceList: string) {
    // @ts-ignore
    return this.priceListData
      .find((item) => selectedPriceList === item.priceList).currency;
  }

  static getRateObj(
    priceList: string,
    currency: string,
    assetType: string,
    complexityLevel: string,
    type: string,
    template: string,
    pss: string,
  ) {
    /* if (template === '') {
      type = 'New';
    } */
    const obj = this.data.find(
      (item) => (
        assetType === item['Content production (Asset type)']
        && complexityLevel === item['Complexity level']
        && template === item.Template
        && pss === item.PSS
      ),
    );
    if (!obj || !type) {
      return { rate: 0, innerRate: 0 };
    }

    if (['EUR', 'USD'].includes(currency)) {
      return {
        // @ts-ignore
        rate: obj[type][currency].rate,
        // @ts-ignore
        innerRate: obj[type][currency].innerRate,
      };
    }
    return {
      // @ts-ignore
      rate: Utils.roundDecimal(obj[type].rate * CurrencyExchange.usdTo(currency)),
      // @ts-ignore
      innerRate: Utils.roundDecimal(obj[type].innerRate * CurrencyExchange.usdTo(currency)),
    };
  }

  private static getDataByAssetType(selectedAssetType: string) {
    return this.data
      .filter((item) => item['Content production (Asset type)'] === selectedAssetType);
  }

  private static priceListData = [
    { priceList: 'Global USD PL', currency: 'USD' },
    { priceList: 'Global EUR PL', currency: 'EUR' },
    { priceList: 'Global UAH PL', currency: 'UAH' },
  ];

  // this is data for USD
  private static data = flatRates as FlatRate[];
}
