import customRates from '@/data-sources/CustomRates.json';
import { CustomRate } from "@/types/rates/CustomRate";

export default class CustomRateCard {
  static getTeamsList(selectedPriceList: string) {
    const filteredData = this.data.filter((item) => {
      return item.priceList === selectedPriceList;
    })
    const names: string[] = [];
    filteredData.forEach((item) => {
      if (!names.includes(item.product)) {
        names.push(item.product);
      }
    });
    names.unshift('All');
    return names;
  }

  static getPriceList() {
    let names: string[] = [];
    this.data.forEach((item) => {
      if (!names.includes(item.priceList)) {
        names.push(item.priceList);
      }
    });
    return names.sort();
  }

  static getCurrency(selectedPriceList: string) {
    // @ts-ignore
    return this.data.find((item) => item.priceList === selectedPriceList).currency;
  }

  static getSpecialistData(specialistId: string, selectedPriceList: string) {
    return this.data.find((item) => item.roleNameCRM === specialistId
      && item.priceList === selectedPriceList);
  }

  static getInnerRate(specialistId: string, priceList: string) {
    const specialistData = this.getSpecialistData(specialistId, priceList);
    // @ts-ignore
    return specialistData.innerRate;
  }

  static getSpecialistList(selectedPriceList: string, selectedTeam: string) {
    const specialists: Record<string, string>[] = [];
    this.data.forEach((item) => {
      if (item.priceList === selectedPriceList) {
        if (selectedTeam === 'All') {
          specialists.push({id: item.roleNameCRM, name: item.localName});
        } else if (selectedTeam === item.product) {
          specialists.push({id: item.roleNameCRM, name: item.localName});
        }
      }
    });
    return specialists;
  }

  static getDataForStandardCalculation() {
    return this.data;
  }

  private static data = customRates as CustomRate[];
}
