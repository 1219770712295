import { API } from 'aws-amplify';

export default class Users {
  static getAllUsers() {
    return API.get('amz', '/employees', {});
  }

  static async getUsersInGroup(groupName: string) {
    let NextToken = null;
    const users = [];
    do {
      try {
        // @ts-ignore
        // eslint-disable-next-line operator-linebreak
        const result =
          // eslint-disable-next-line no-await-in-loop
          await this.getUsersInGroupWithNextToken(groupName, NextToken);
        if (!result.success) {
          NextToken = null;
        }
        // @ts-ignore
        users.push(...result.data.Users);
        NextToken = result.data.NextToken;
      } catch (err) {
        console.log('getUsersInGroup error', err);
      }
    } while (NextToken);
    return users.map((item) => {
      let nameAndEmail: string;
      // @ts-ignore
      const email = item.Attributes.find((attr) => attr.Name === 'email').Value;
      // @ts-ignore
      const name = item.Attributes.find((attr) => attr.Name === 'name').Value;
      if (name) {
        nameAndEmail = `${name} (${email})`;
      } else {
        nameAndEmail = email;
      }
      return {
        id: item.Username,
        nameAndEmail,
      };
    }).sort((a, b) => a.nameAndEmail.localeCompare(b.nameAndEmail));
  }

  private static getUsersInGroupWithNextToken(name: string, NextToken: string | null) {
    return API.get('amz', '/group', {
      queryStringParameters: {
        name,
        NextToken,
      },
    });
  }
}
