








































































































































import Vue from 'vue';
import CreateNewPopup from '@/components/modals/CreateNewPopup.vue';
import ErrorPopup from '@/components/modals/ErrorPopup.vue';
import { API, Auth } from 'aws-amplify';
import { listCalculatorInputDatas } from '@/graphql/queries';
import { deleteCalculatorInputData, deleteJobData } from '@/graphql/mutations';
import { CalculatorInputData } from '@/API';
import {
  onCreateCalculatorInputData,
  onDeleteCalculatorInputData,
  onUpdateCalculatorInputData,
} from '@/graphql/subscriptions';
import Norms from '@/data-sources/Norms';
import CustomRateCard from '@/data-sources/CustomRateCard';
import RateCard from '@/data-sources/RateCard';
import { SearchFilter } from '@/types/list/SearchFilter';
import CustomAssetTypes from '@/data-sources/CustomAssetTypes';
import Users from '@/services/Users';

export default Vue.extend({
  name: 'List',
  components: {
    ErrorPopup,
    CreateNewPopup,
  },
  data: () => ({
    items: [] as Array<CalculatorInputData>,
    dialogDelete: false,
    isLoading: false,
    loadLimit: 200,
    errorData: {
      show: false,
      errorObject: {},
    },
    usersList: [] as Array<string>,
    showCreateNewDialog: false,
    filters: {
      selectedPriceList: 'All',
      selectedAssetType: 'All',
      selectedJobType: 'All',
      calculationType: 'All',
      createdBy: 'All',
    } as SearchFilter,
    frontEndSearch: '',
    headers: [
      {
        text: 'Estimation Name',
        align: 'start',
        value: 'name',
      },
      { text: 'Price List', value: 'selectedPriceList' },
      { text: 'Asset', value: 'selectedAssetType' },
      // { text: 'Job', value: 'selectedJobType' },
      { text: 'Created by', value: 'createdBy' },
      {
        text: 'Created At',
        value: 'createdAt',
        dataType: 'Date',
        filterable: false,
      },
      {
        text: 'Updated At',
        value: 'updatedAt',
        dataType: 'Date',
        filterable: false,
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        filterable: false,
      },
    ],
    sort: {
      sortBy: 'updatedAt',
      sortDesc: true,
    },
    itemToDelete: null as null | CalculatorInputData,
  }),
  computed: {
    displayItems() {
      return this.items.filter((item) => {
        let isItemIncluded = true;
        Object.keys(this.filters).forEach((key) => {
          // @ts-ignore
          if (this.filters[key as keyof SearchFilter] !== 'All' && item[key] !== this.filters[key]) {
            isItemIncluded = false;
          }
        });
        return isItemIncluded;
      });
    },
    assetTypeList() {
      const list = Norms.getAssetTypeNames();
      list.push(...CustomAssetTypes.getAssetTypeNames());
      list.unshift('All'); // this adds to array but returns a number
      return list;
    },
    priceList() {
      const list = RateCard.getPriceList();
      CustomRateCard.getPriceList().forEach((priceList) => {
        if (!list.includes(priceList)) {
          list.push(priceList);
        }
      });
      list.sort();
      list.unshift('All');
      return list;
    },
    jobTypeList() {
      let list: Array<string>;
      if (CustomAssetTypes.getAssetTypeNames().includes(this.filters.selectedAssetType)) {
        list = CustomAssetTypes.getJobTypeNames(this.filters.selectedAssetType);
      } else {
        list = Norms.getJobTypeNames(this.filters.selectedAssetType);
      }
      list.unshift('All');
      if (!list.includes(this.filters.selectedJobType)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.filters.selectedJobType = 'All';
      }
      return list;
    },
    calculationTypeList() {
      return ['All', 'standard', 'custom', 'flat rate'];
    },
  },
  methods: {
    async fetchSavedItems(nextToken: string | null = null) {
      try {
        this.isLoading = true;
        // @ts-ignore
        const result = await API.graphql({
          query: listCalculatorInputDatas,
          variables: {
            limit: this.loadLimit,
            nextToken,
          },
        });
        // console.log(result);
        if (!nextToken) {
          this.items = [];
        }
        // @ts-ignore
        this.items.push(...result?.data?.listCalculatorInputDatas.items);
        // @ts-ignore
        const nextFetchToken = result?.data?.listCalculatorInputDatas.nextToken;
        if (nextFetchToken) {
          await this.fetchSavedItems(nextFetchToken);
        }
        this.isLoading = false;
      } catch (error) {
        console.log('fetchSavedItems error', error);
        // @ts-ignore
        this.presentError(error);
        this.isLoading = false;
      }
    },
    openItem(id: string) {
      this.$router.push({ name: 'CalculatorPage', params: { id } });
    },
    rowClickHandler(value: CalculatorInputData) {
      // @ts-ignore
      this.openItem(value.id!);
    },
    editClickHandler(id: string) {
      // @ts-ignore
      this.openItem(id);
    },
    openDeleteConfirmationDialog(item: CalculatorInputData) {
      this.itemToDelete = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        await API.graphql({
          query: deleteCalculatorInputData,
          variables: { input: { id: this.itemToDelete!.id } },
        });
        await API.graphql({
          query: deleteJobData,
          variables: { input: { id: this.itemToDelete!.jobData!.id } },
        });
      } catch (error) {
        console.log('delete error:', error);
        // @ts-ignore
        this.presentError(error);
      }
      // @ts-ignore
      this.closeDelete();
    },
    presentError(msg: Record<string, any>) {
      this.errorData.show = true;
      this.errorData.errorObject = msg;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.itemToDelete = null;
    },
    clearFilters() {
      this.filters = {
        selectedPriceList: 'All',
        selectedAssetType: 'All',
        selectedJobType: 'All',
        calculationType: 'All',
        createdBy: 'All',
      };
    },
    subscribe() {
      // it did not work to pass owner in options, thus, subscriptions: { level: public }
      API.graphql({ query: onCreateCalculatorInputData })
        // @ts-ignore
        .subscribe({
          // @ts-ignore
          next: (eventData) => {
            const newItem = eventData.value.data.onCreateCalculatorInputData;
            Auth.currentSession()
              .then((session) => {
                const groups = session.getAccessToken().decodePayload()['cognito:groups'];
                const username = session.getAccessToken().decodePayload().username;
                if (groups.includes('Admin') || newItem.approvers.includes(username)) {
                  this.items.unshift(newItem);
                }
              });
          },
        });
      API.graphql({ query: onUpdateCalculatorInputData })
        // @ts-ignore
        .subscribe({
          // @ts-ignore
          next: (eventData) => {
            const updated = eventData.value.data.onUpdateCalculatorInputData;
            const index = this.items.findIndex((item) => item.id === updated.id);
            if (index > -1) {
              this.items.splice(index, 1); // remove and and add
              this.items.unshift(updated);
            }
          },
        });
      API.graphql({ query: onDeleteCalculatorInputData })
        // @ts-ignore
        .subscribe({
          // @ts-ignore
          next: (eventData) => {
            const deleted = eventData.value.data.onDeleteCalculatorInputData;
            const index = this.items.findIndex((item) => item.id === deleted.id);
            if (index > -1) {
              this.items.splice(index, 1);
            }
          },
        });
    },
    async setUsersList() {
      const userListData = await Users.getAllUsers();
      if (userListData.success) {
        // @ts-ignore
        this.usersList = userListData.allUsers.map((item) => item.Attributes.find((attr) => attr.Name === 'email').Value);
        this.usersList.sort();
        this.usersList.unshift('All');
      } else {
        console.log('userListData.error', userListData.error);
      }
    },
  },
  watch: {
    dialogDelete(val) {
      // @ts-ignore
      return val || this.closeDelete();
    },
  },
  async created() {
    await this.fetchSavedItems();
    await this.setUsersList();
    // @ts-ignore
    this.subscribe(); // Property 'subscribe' does not exist on type 'CombinedVueInstance
  },
});
