import { Auth } from 'aws-amplify';

export default class AuthUtils {
  static async getIdTokenObject() {
    return (await Auth.currentSession()).getIdToken();
  }

  static async getCurrentUserEmail() {
    return (await this.getIdTokenObject()).decodePayload().email;
  }

  static async getCurrentUserName() {
    return (await this.getIdTokenObject()).decodePayload().name;
  }

  static async getUserName() {
    return (await Auth.currentAuthenticatedUser()).username;
  }

  static async isAdmin() {
    return (await Auth.currentSession())
      .getAccessToken().decodePayload()['cognito:groups'].includes('Admin');
  }

  static async isNotInDelivery() {
    return !(await Auth.currentSession())
      .getAccessToken().decodePayload()['cognito:groups'].includes('Delivery');
  }
}
