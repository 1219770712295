import { CalculatorInputData } from '@/types/input/CalculatorInputData';

export default class DataPreparer {
  static createInput(inset: CalculatorInputData) {
    const input = {
      name: inset.name,
      type: inset.name,
      status: inset.status,
      calculationType: inset.calculationType,
      selectedAssetType: inset.selectedAssetType,
      selectedJobType: inset.selectedJobType,
      selectedTargetSystem: inset.selectedTargetSystem,
      selectedScopingComplexity: inset.selectedScopingComplexity,
      selectedPriceList: inset.selectedPriceList,
      jiraEpic: inset.jiraEpic,
      jiraSummary: inset.jiraSummary,
      reuseRatioPercentage: inset.reuseRatioPercentage,
      discountPercentage: inset.discountPercentage,
      checkboxes: inset.checkboxes,
      numberOfSlides: inset.numberOfSlides,
      createdBy: inset.createdBy,
      owner: inset.owner,
      currentApproverId: inset.currentApproverId,
      approvedOrRejectedBy: inset.approvedOrRejectedBy,
      rejectedComment: inset.rejectedComment,
      approvers: inset.approvers,
      calculatorInputDataJobDataId: inset.jobData.id,
    };
    if (inset.id) {
      // @ts-ignore
      input.id = inset.id;
    }
    return input;
  }
}
