export default class CustomAssetTypes {
  // Warning: only these asset types are needed (not all from data below)
  static getAssetTypeNames() {
    return [
      'Change management - consulting',
      'Online courses',
      'Training Sessions',
      'Workshops',
      'Custom web services',
      'Banner (Image)',
      'GIF',
      'iPDF',
      'Power Point Presentation',
      'Printed materials',
      'Social micro content',
      'Custom design services',
      'eDetailer',
      'eDetailer Template',
      'Responsive eDetailer',
      'eLearning',
      'Email Template',
      'Mass mailing',
      'RTE',
      'Veeva fragments',
      'Web Banner',
      'AMP Email',
      'Veeva Module',
      'Veeva MyInsights',
      '2D Video',
      '3D Video',
      'Custom Video services',
      'CX Survey',
      'LandingPage - CMS based',
      'LandingPage - Custom development',
      'LandingPage - Template',
      'Web sites - CMS based',
      'Web sites - Custom Development',
      'Webinars',
      'Subscription - Creative Services',
      'Dedicated Team - BA',
      'Dedicated Team - CSM',
      'Dedicated Team - Delivery, Deployment & Production',
      'Dedicated Team - Digital Librarian',
      'Dedicated Team - Omnichannel Specialists',
      'Dedicated Team - Subject Matter Experts',
      'Apps - Support',
      'AR / VR / MR',
      'Mobile apps',
      'Data analysis & BI',
      'Atlassian development',
      'DevOps services',
      'Salesforce CRM/CLM',
      'Salesforce CRM/CLM - Support',
      'AEM Web portals (Enterprise)',
      'Web portals (Enterprise)',
      'Web portals (Enterprise) - Support',
      'eWizard - Custom services',
      'eWizard - Integrations & custom development',
      'eWizard - Reporting / PowerBI / Data and Tracking Stream',
      'eWizard Briefing Tool - licenses',
      'eWizard Navigate - licenses',
      'eWizard Planner - licenses',
      'eWizard Platform - licenses',
      'eWizard Platform - PAYG',
      'eWizard - PoC',
      'eWizard - Rollout',
      'eWizard - Support',
      'Veeva-related services',
      'Medical Marketing - Brand strategy',
      'Medical Marketing - Medical content',
      'Omnichannel - Campaigns',
      'Omnichannel - Consulting',
      'Omnichannel - Integrations & Set-up',
      'Omnichannel - Trainings',
      'FTE (Fixed price contract)',
      'FTE (time and material contract)',
      'Platforce - Integrations & custom development',
      'Platforce - Reporting / PowerBI / Data and Tracking Stream',
      'Platforce CRM/CLM - Licenses',
      'Platforce Pulse - Licenses',
      'Platforce Remote Calls - Licenses',
      'Platforce - PAYG',
      'Platforce CRM/CLM - Rollout',
      'Platforce - Support',
      'Reselling - Hosting',
      'Reselling - Licenses',
      'Reselling - Services',
    ];
  }

  static getJobTypeNames(assetType: string) {
    const names: Array<string> = [];
    this.data.forEach((item) => {
      if ((assetType === item.assetType) && !names.includes(item.jobType)) {
        names.push(item.jobType);
      }
    });
    return names;
  }

  // Warning: not all asset types are needed
  private static data = [
    {
      assetType: 'Change management - consulting',
      jobType: '',
    },
    {
      assetType: 'Online courses',
      jobType: '',
    },
    {
      assetType: 'Training Sessions',
      jobType: '',
    },
    {
      assetType: 'Workshops',
      jobType: '',
    },
    {
      assetType: 'Custom web services',
      jobType: '',
    },
    {
      assetType: 'Banner (Image)',
      jobType: '',
    },
    {
      assetType: 'GIF',
      jobType: '',
    },
    {
      assetType: 'iPDF',
      jobType: '',
    },
    {
      assetType: 'Power Point Presentation',
      jobType: '',
    },
    {
      assetType: 'Printed materials',
      jobType: '',
    },
    {
      assetType: 'Social micro content',
      jobType: '',
    },
    {
      assetType: 'Custom design services',
      jobType: '',
    },
    {
      assetType: 'eDetailer',
      jobType: '',
    },
    {
      assetType: 'eDetailer Template',
      jobType: '',
    },
    {
      assetType: 'Responsive eDetailer',
      jobType: '',
    },
    {
      assetType: 'eLearning',
      jobType: '',
    },
    {
      assetType: 'Email Template',
      jobType: '',
    },
    {
      assetType: 'Mass mailing',
      jobType: '',
    },
    {
      assetType: 'RTE',
      jobType: '',
    },
    {
      assetType: 'Veeva fragments',
      jobType: '',
    },
    {
      assetType: 'Web Banner',
      jobType: '',
    },
    {
      assetType: 'AMP Email',
      jobType: '',
    },
    {
      assetType: 'Veeva Module',
      jobType: '',
    },
    {
      assetType: 'Veeva MyInsights',
      jobType: '',
    },
    {
      assetType: '2D Video',
      jobType: '',
    },
    {
      assetType: '3D Video',
      jobType: '',
    },
    {
      assetType: 'Custom Video services',
      jobType: '',
    },
    {
      assetType: 'CX Survey',
      jobType: '',
    },
    {
      assetType: 'LandingPage - CMS based',
      jobType: '',
    },
    {
      assetType: 'LandingPage - Custom development',
      jobType: '',
    },
    {
      assetType: 'LandingPage - Template',
      jobType: '',
    },
    {
      assetType: 'Web sites - CMS based',
      jobType: '',
    },
    {
      assetType: 'Web sites - Custom Development',
      jobType: '',
    },
    {
      assetType: 'Webinars',
      jobType: '',
    },
    {
      assetType: 'Subscription - Creative Services',
      jobType: '',
    },
    {
      assetType: 'Dedicated Team - BA',
      jobType: '',
    },
    {
      assetType: 'Dedicated Team - CSM',
      jobType: '',
    },
    {
      assetType: 'Dedicated Team - Delivery, Deployment & Production',
      jobType: '',
    },
    {
      assetType: 'Dedicated Team - Digital Librarian',
      jobType: '',
    },
    {
      assetType: 'Dedicated Team - Omnichannel Specialists',
      jobType: '',
    },
    {
      assetType: 'Dedicated Team - Subject Matter Experts',
      jobType: '',
    },
    {
      assetType: 'Apps - Support',
      jobType: '',
    },
    {
      assetType: 'AR / VR / MR',
      jobType: '',
    },
    {
      assetType: 'Mobile apps',
      jobType: '',
    },
    {
      assetType: 'Data analysis & BI',
      jobType: '',
    },
    {
      assetType: 'Atlassian development',
      jobType: '',
    },
    {
      assetType: 'DevOps services',
      jobType: '',
    },
    {
      assetType: 'Salesforce CRM/CLM',
      jobType: '',
    },
    {
      assetType: 'Salesforce CRM/CLM - Support',
      jobType: '',
    },
    {
      assetType: 'AEM Web portals (Enterprise)',
      jobType: '',
    },
    {
      assetType: 'Web portals (Enterprise)',
      jobType: '',
    },
    {
      assetType: 'Web portals (Enterprise) - Support',
      jobType: '',
    },
    {
      assetType: 'eWizard - Custom services',
      jobType: '',
    },
    {
      assetType: 'eWizard - Integrations & custom development',
      jobType: '',
    },
    {
      assetType: 'eWizard - Reporting / PowerBI / Data and Tracking Stream',
      jobType: '',
    },
    {
      assetType: 'eWizard Briefing Tool - licenses',
      jobType: '',
    },
    {
      assetType: 'eWizard Navigate - licenses',
      jobType: '',
    },
    {
      assetType: 'eWizard Planner - licenses',
      jobType: '',
    },
    {
      assetType: 'eWizard Platform - licenses',
      jobType: '',
    },
    {
      assetType: 'eWizard Platform - PAYG',
      jobType: '',
    },
    {
      assetType: 'eWizard - PoC',
      jobType: '',
    },
    {
      assetType: 'eWizard - Rollout',
      jobType: '',
    },
    {
      assetType: 'eWizard - Support',
      jobType: '',
    },
    {
      assetType: 'Veeva-related services',
      jobType: '',
    },
    {
      assetType: 'Medical Marketing - Brand strategy',
      jobType: '',
    },
    {
      assetType: 'Medical Marketing - Medical content',
      jobType: '',
    },
    {
      assetType: 'Omnichannel - Campaigns',
      jobType: '',
    },
    {
      assetType: 'Omnichannel - Consulting',
      jobType: '',
    },
    {
      assetType: 'Omnichannel - Integrations & Set-up',
      jobType: '',
    },
    {
      assetType: 'Omnichannel - Trainings',
      jobType: '',
    },
    {
      assetType: 'FTE (Fixed price contract)',
      jobType: '',
    },
    {
      assetType: 'FTE (time and material contract)',
      jobType: '',
    },
    {
      assetType: 'Platforce - Integrations & custom development',
      jobType: '',
    },
    {
      assetType: 'Platforce - Reporting / PowerBI / Data and Tracking Stream',
      jobType: '',
    },
    {
      assetType: 'Platforce CRM/CLM - Licenses',
      jobType: '',
    },
    {
      assetType: 'Platforce Pulse - Licenses',
      jobType: '',
    },
    {
      assetType: 'Platforce Remote Calls - Licenses',
      jobType: '',
    },
    {
      assetType: 'Platforce - PAYG',
      jobType: '',
    },
    {
      assetType: 'Platforce CRM/CLM - Rollout',
      jobType: '',
    },
    {
      assetType: 'Platforce - Support',
      jobType: '',
    },
    {
      assetType: 'Reselling - Hosting',
      jobType: '',
    },
    {
      assetType: 'Reselling - Licenses',
      jobType: '',
    },
    {
      assetType: 'Reselling - Services',
      jobType: '',
    },
  ];
}
