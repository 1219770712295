/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCalculatorInputData = /* GraphQL */ `
  mutation CreateCalculatorInputData(
    $input: CreateCalculatorInputDataInput!
    $condition: ModelCalculatorInputDataConditionInput
  ) {
    createCalculatorInputData(input: $input, condition: $condition) {
      id
      name
      type
      status
      calculationType
      selectedAssetType
      selectedJobType
      selectedTargetSystem
      selectedScopingComplexity
      selectedPriceList
      jiraEpic
      jiraSummary
      reuseRatioPercentage
      discountPercentage
      checkboxes {
        Scoping
        Design
        Deploy
        Campaign_Execution
      }
      numberOfSlides {
        simple {
          imageBased
          html5
        }
        medium {
          imageBased
          html5
        }
        complex {
          imageBased
          html5
        }
      }
      jobData {
        id
        jobs
        services
        createdAt
        updatedAt
      }
      createdBy
      owner
      currentApproverId
      approvedOrRejectedBy
      rejectedComment
      approvers
      createdAt
      updatedAt
    }
  }
`;
export const updateCalculatorInputData = /* GraphQL */ `
  mutation UpdateCalculatorInputData(
    $input: UpdateCalculatorInputDataInput!
    $condition: ModelCalculatorInputDataConditionInput
  ) {
    updateCalculatorInputData(input: $input, condition: $condition) {
      id
      name
      type
      status
      calculationType
      selectedAssetType
      selectedJobType
      selectedTargetSystem
      selectedScopingComplexity
      selectedPriceList
      jiraEpic
      jiraSummary
      reuseRatioPercentage
      discountPercentage
      checkboxes {
        Scoping
        Design
        Deploy
        Campaign_Execution
      }
      numberOfSlides {
        simple {
          imageBased
          html5
        }
        medium {
          imageBased
          html5
        }
        complex {
          imageBased
          html5
        }
      }
      jobData {
        id
        jobs
        services
        createdAt
        updatedAt
      }
      createdBy
      owner
      currentApproverId
      approvedOrRejectedBy
      rejectedComment
      approvers
      createdAt
      updatedAt
    }
  }
`;
export const deleteCalculatorInputData = /* GraphQL */ `
  mutation DeleteCalculatorInputData(
    $input: DeleteCalculatorInputDataInput!
    $condition: ModelCalculatorInputDataConditionInput
  ) {
    deleteCalculatorInputData(input: $input, condition: $condition) {
      id
      name
      type
      status
      calculationType
      selectedAssetType
      selectedJobType
      selectedTargetSystem
      selectedScopingComplexity
      selectedPriceList
      jiraEpic
      jiraSummary
      reuseRatioPercentage
      discountPercentage
      checkboxes {
        Scoping
        Design
        Deploy
        Campaign_Execution
      }
      numberOfSlides {
        simple {
          imageBased
          html5
        }
        medium {
          imageBased
          html5
        }
        complex {
          imageBased
          html5
        }
      }
      jobData {
        id
        jobs
        services
        createdAt
        updatedAt
      }
      createdBy
      owner
      currentApproverId
      approvedOrRejectedBy
      rejectedComment
      approvers
      createdAt
      updatedAt
    }
  }
`;
export const createJobData = /* GraphQL */ `
  mutation CreateJobData(
    $input: CreateJobDataInput!
    $condition: ModelJobDataConditionInput
  ) {
    createJobData(input: $input, condition: $condition) {
      id
      jobs
      services
      createdAt
      updatedAt
    }
  }
`;
export const updateJobData = /* GraphQL */ `
  mutation UpdateJobData(
    $input: UpdateJobDataInput!
    $condition: ModelJobDataConditionInput
  ) {
    updateJobData(input: $input, condition: $condition) {
      id
      jobs
      services
      createdAt
      updatedAt
    }
  }
`;
export const deleteJobData = /* GraphQL */ `
  mutation DeleteJobData(
    $input: DeleteJobDataInput!
    $condition: ModelJobDataConditionInput
  ) {
    deleteJobData(input: $input, condition: $condition) {
      id
      jobs
      services
      createdAt
      updatedAt
    }
  }
`;
