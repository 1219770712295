import { Norm } from '@/types/Norm';

export default class Norms {
  static getNormsArray(assetType: string, jobType: string) {
    return this.data.filter((dataItem) => dataItem['Asset Type'] === assetType
      && dataItem['Job type'] === jobType);
  }

  static getNormsLine(assetType: string, jobType: string, item: string) {
    return this.getNormsArray(assetType, jobType)
      .find((norms) => item === norms['Item']);
  }

  static getAssetTypeNames() {
    const names: string[] = [];
    this.data.forEach((item) => {
      if (!names.includes(item['Asset Type'])) {
        names.push(item['Asset Type']);
      }
    });
    return names;
  }

  static getJobTypeNames(assetType: string) {
    const names: Array<string> = [];
    this.data.forEach((item) => {
      if ((assetType === item['Asset Type']) && !names.includes(item['Job type'])) {
        names.push(item['Job type']);
      }
    });
    return names;
  }

  private static data: Array<Norm> = [
    {
      "Asset Type": "Presentation",
      "Item": "General Styles / General Work",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": "0%",
      "SCPNG_CH": "200%",
      "SCPNG_MedAdv": "0%",
      "DSGN_CrtvPM": "300%",
      "DSGN_SDsgn": "600%",
      "DSGN_Dsgnr": "0%",
      "DSGN_PR/QA": "0%",
      "BLD_PM": "300%",
      "BLD_SDvlpr": "600%",
      "BLD_Dvlpr": "0%",
      "TST_QAMngr": "100%",
      "TST_QA": "200%",
      "DPLY_DplmMngr": "25%",
      "DPLY_DplmEngnr": "25%",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 2,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 1.5,
      "TST_QAMngr": "-",
      "TST_QA": 0.35,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 3,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 3,
      "TST_QAMngr": "-",
      "TST_QA": 0.75,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 4,
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 5,
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": 1.2,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 3,
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 5,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 3,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 1,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 5,
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 8,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 5,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 2,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 8,
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 10,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 7,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 3,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Custom SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": "-",
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": "-",
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": "-",
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "General Styles / General Work",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": "0%",
      "SCPNG_CH": "200%",
      "SCPNG_MedAdv": "0%",
      "DSGN_CrtvPM": "300%",
      "DSGN_SDsgn": "800%",
      "DSGN_Dsgnr": "0%",
      "DSGN_PR/QA": "0%",
      "BLD_PM": "300%",
      "BLD_SDvlpr": "1200%",
      "BLD_Dvlpr": "0%",
      "TST_QAMngr": "100%",
      "TST_QA": "200%",
      "DPLY_DplmMngr": "25%",
      "DPLY_DplmEngnr": "25%",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 2,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 2.5,
      "TST_QAMngr": "-",
      "TST_QA": 0.65,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 3,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 5,
      "TST_QAMngr": "-",
      "TST_QA": 1.25,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 4,
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 7,
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": 1.75,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": "300%",
      "SCPNG_CH": "0%",
      "SCPNG_MedAdv": "0%",
      "DSGN_CrtvPM": "500%",
      "DSGN_SDsgn": "0%",
      "DSGN_Dsgnr": "0%",
      "DSGN_PR/QA": "0%",
      "BLD_PM": "300%",
      "BLD_SDvlpr": "0%",
      "BLD_Dvlpr": "0%",
      "TST_QAMngr": "100%",
      "TST_QA": "0%",
      "DPLY_DplmMngr": "100%",
      "DPLY_DplmEngnr": "0%",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 5,
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 8,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 5,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 2,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 8,
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 10,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 7,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 3,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Custom SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": "-",
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": "-",
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": "-",
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "General Styles / General Work",
      "Job type": "Update",
      "SCPNG_LM": "0%",
      "SCPNG_CH": "100%",
      "SCPNG_MedAdv": "0%",
      "DSGN_CrtvPM": "200%",
      "DSGN_SDsgn": "100%",
      "DSGN_Dsgnr": "0%",
      "DSGN_PR/QA": "0%",
      "BLD_PM": "200%",
      "BLD_SDvlpr": "300%",
      "BLD_Dvlpr": "0%",
      "TST_QAMngr": "100%",
      "TST_QA": "100%",
      "DPLY_DplmMngr": "25%",
      "DPLY_DplmEngnr": "25%",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple",
      "Job type": "Update",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 0.75,
      "TST_QAMngr": "-",
      "TST_QA": 0.2,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium",
      "Job type": "Update",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 1,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 1.5,
      "TST_QAMngr": "-",
      "TST_QA": 0.35,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex",
      "Job type": "Update",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 2,
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 2.5,
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": 0.75,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple SLA",
      "Job type": "Update",
      "SCPNG_LM": "200%",
      "SCPNG_CH": "0%",
      "SCPNG_MedAdv": "0%",
      "DSGN_CrtvPM": "300%",
      "DSGN_SDsgn": "0%",
      "DSGN_Dsgnr": "0%",
      "DSGN_PR/QA": "0%",
      "BLD_PM": "200%",
      "BLD_SDvlpr": "0%",
      "BLD_Dvlpr": "0%",
      "TST_QAMngr": "100%",
      "TST_QA": "0%",
      "DPLY_DplmMngr": "100%",
      "DPLY_DplmEngnr": "0%",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium SLA",
      "Job type": "Update",
      "SCPNG_LM": 3,
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 5,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 2,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 1,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex SLA",
      "Job type": "Update",
      "SCPNG_LM": 5,
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 7,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 3,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 2,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Custom SLA",
      "Job type": "Update",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": "-",
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": "-",
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": "-",
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "General Styles / General Work",
      "Job type": "Localisation",
      "SCPNG_LM": "0%",
      "SCPNG_CH": "100%",
      "SCPNG_MedAdv": "0%",
      "DSGN_CrtvPM": "200%",
      "DSGN_SDsgn": "100%",
      "DSGN_Dsgnr": "0%",
      "DSGN_PR/QA": "0%",
      "BLD_PM": "200%",
      "BLD_SDvlpr": "400%",
      "BLD_Dvlpr": "0%",
      "TST_QAMngr": "100%",
      "TST_QA": "100%",
      "DPLY_DplmMngr": "25%",
      "DPLY_DplmEngnr": "25%",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple",
      "Job type": "Localisation",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.03,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 0.25,
      "TST_QAMngr": "-",
      "TST_QA": 0.5,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium",
      "Job type": "Localisation",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.03,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 1,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 0.5,
      "TST_QAMngr": "-",
      "TST_QA": 1,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex",
      "Job type": "Localisation",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.03,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 2,
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 1.5,
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": 1.5,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple SLA",
      "Job type": "Localisation",
      "SCPNG_LM": "200%",
      "SCPNG_CH": "0%",
      "SCPNG_MedAdv": "0%",
      "DSGN_CrtvPM": "100%",
      "DSGN_SDsgn": "0%",
      "DSGN_Dsgnr": "0%",
      "DSGN_PR/QA": "0%",
      "BLD_PM": "200%",
      "BLD_SDvlpr": "0%",
      "BLD_Dvlpr": "0%",
      "TST_QAMngr": "100%",
      "TST_QA": "0%",
      "DPLY_DplmMngr": "100%",
      "DPLY_DplmEngnr": "0%",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 3,
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 3,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 2,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 4,
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 3,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 5,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 3,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Custom SLA",
      "Job type": "Localisation",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": "-",
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": "-",
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": "-",
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "General Styles / General Work",
      "Job type": "Conversion",
      "SCPNG_LM": "0%",
      "SCPNG_CH": "50%",
      "SCPNG_MedAdv": "0%",
      "DSGN_CrtvPM": "200%",
      "DSGN_SDsgn": "100%",
      "DSGN_Dsgnr": "0%",
      "DSGN_PR/QA": "0%",
      "BLD_PM": "100%",
      "BLD_SDvlpr": "400%",
      "BLD_Dvlpr": "0%",
      "TST_QAMngr": "100%",
      "TST_QA": "150%",
      "DPLY_DplmMngr": "25%",
      "DPLY_DplmEngnr": "25%",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple",
      "Job type": "Conversion",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.03,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 2.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 0.25,
      "TST_QAMngr": "-",
      "TST_QA": 0.2,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium",
      "Job type": "Conversion",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.03,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 3.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 0.5,
      "TST_QAMngr": "-",
      "TST_QA": 0.4,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex",
      "Job type": "Conversion",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.03,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 4.5,
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 1.5,
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": 0.65,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple SLA",
      "Job type": "Conversion",
      "SCPNG_LM": "200%",
      "SCPNG_CH": "100%",
      "SCPNG_MedAdv": "100%",
      "DSGN_CrtvPM": "500%",
      "DSGN_SDsgn": "0%",
      "DSGN_Dsgnr": "0%",
      "DSGN_PR/QA": "0%",
      "BLD_PM": "200%",
      "BLD_SDvlpr": "0%",
      "BLD_Dvlpr": "0%",
      "TST_QAMngr": "100%",
      "TST_QA": "0%",
      "DPLY_DplmMngr": "100%",
      "DPLY_DplmEngnr": "0%",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium SLA",
      "Job type": "Conversion",
      "SCPNG_LM": 2,
      "SCPNG_CH": 2,
      "SCPNG_MedAdv": 1,
      "DSGN_CrtvPM": 8,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 3,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 1,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex SLA",
      "Job type": "Conversion",
      "SCPNG_LM": 3,
      "SCPNG_CH": 3,
      "SCPNG_MedAdv": 2,
      "DSGN_CrtvPM": 10,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 4,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 2,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Custom SLA",
      "Job type": "Conversion",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": "-",
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": "-",
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": "-",
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "General Styles / General Work",
      "Job type": "Image Based",
      "SCPNG_LM": "0%",
      "SCPNG_CH": "50%",
      "SCPNG_MedAdv": "0%",
      "DSGN_CrtvPM": "200%",
      "DSGN_SDsgn": "600%",
      "DSGN_Dsgnr": "0%",
      "DSGN_PR/QA": "0%",
      "BLD_PM": "100%",
      "BLD_SDvlpr": "400%",
      "BLD_Dvlpr": "0%",
      "TST_QAMngr": "100%",
      "TST_QA": "150%",
      "DPLY_DplmMngr": "25%",
      "DPLY_DplmEngnr": "25%",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple",
      "Job type": "Image Based",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.03,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 2.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 0.25,
      "TST_QAMngr": "-",
      "TST_QA": 0.1,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium",
      "Job type": "Image Based",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.03,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": 3.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": 0.5,
      "TST_QAMngr": "-",
      "TST_QA": 0.2,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex",
      "Job type": "Image Based",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.03,
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 4.5,
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 0.75,
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": 0.3,
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": 0.05,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Simple SLA",
      "Job type": "Image Based",
      "SCPNG_LM": "200%",
      "SCPNG_CH": "0%",
      "SCPNG_MedAdv": "0%",
      "DSGN_CrtvPM": "500%",
      "DSGN_SDsgn": "0%",
      "DSGN_Dsgnr": "0%",
      "DSGN_PR/QA": "0%",
      "BLD_PM": "200%",
      "BLD_SDvlpr": "0%",
      "BLD_Dvlpr": "0%",
      "TST_QAMngr": "100%",
      "TST_QA": "0%",
      "DPLY_DplmMngr": "100%",
      "DPLY_DplmEngnr": "0%",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Medium SLA",
      "Job type": "Image Based",
      "SCPNG_LM": 3,
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 8,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 2,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 1,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Complex SLA",
      "Job type": "Image Based",
      "SCPNG_LM": 4,
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": 10,
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": 3,
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": 2,
      "TST_QA": "-",
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Presentation",
      "Item": "Custom SLA",
      "Job type": "Image Based",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": "-",
      "SCPNG_MedAdv": "-",
      "DSGN_CrtvPM": "-",
      "DSGN_SDsgn": "-",
      "DSGN_Dsgnr": "-",
      "DSGN_PR/QA": "-",
      "BLD_PM": "-",
      "BLD_SDvlpr": "-",
      "BLD_Dvlpr": "-",
      "TST_QAMngr": "-",
      "TST_QA": "-",
      "DPLY_DplmMngr": "-",
      "DPLY_DplmEngnr": "-",
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 2,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 2,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.75,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.7,
      "TST_QAMngr": 0,
      "TST_QA": 0.25,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 1,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.3,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Custom SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 4,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 2,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1,
      "TST_QAMngr": 0,
      "TST_QA": 0.3,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.75,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 2.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 1.5,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 4,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.7,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 3,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 4,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 5,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Custom SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 1,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.25,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.25,
      "TST_QAMngr": 0,
      "TST_QA": 0.15,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0.75,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0.75,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.25,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Custom SLA",
      "Job type": "Update",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 1,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.25,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.15,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.75,
      "TST_QAMngr": 0,
      "TST_QA": 0.2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 1,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.25,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Custom SLA",
      "Job type": "Localisation",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0.5,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.25,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 2,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 4,
      "TST_QAMngr": 0,
      "TST_QA": 1,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0.75,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 6,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple SLA",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium SLA",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex SLA",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Custom SLA",
      "Job type": "Update (new Block)",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0.5,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 2.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.25,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1.5,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1.5,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0.75,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 2,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Simple SLA",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Medium SLA",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Complex SLA",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Approved E-Mail",
      "Item": "Custom SLA",
      "Job type": "Update (move Block)",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 2,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 2,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.75,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.7,
      "TST_QAMngr": 0,
      "TST_QA": 0.25,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 1,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.3,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Custom SLA",
      "Job type": "New Asset w TMPLT",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 4,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 2,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1,
      "TST_QAMngr": 0,
      "TST_QA": 0.3,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.75,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 2.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 1.5,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 4,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.7,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 3,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 4,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 5,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Custom SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 1,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.25,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.25,
      "TST_QAMngr": 0,
      "TST_QA": 0.15,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0.75,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0.75,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.25,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Custom SLA",
      "Job type": "Update",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0.15,
      "BLD_SDvlpr": 2,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.25,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.15,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.75,
      "TST_QAMngr": 0,
      "TST_QA": 0.2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 1.5,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.25,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Custom SLA",
      "Job type": "Localisation",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0.5,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.25,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 2,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 4,
      "TST_QAMngr": 0,
      "TST_QA": 1,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0.75,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 6,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple SLA",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium SLA",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex SLA",
      "Job type": "Update (new Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Custom SLA",
      "Job type": "Update (new Block)",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "General Styles / General Work",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0.5,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 2.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.25,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1.5,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1.5,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0.75,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 2,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Simple SLA",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Medium SLA",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Complex SLA",
      "Job type": "Update (move Block)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Broadcast E-Mail",
      "Item": "Custom SLA",
      "Job type": "Update (move Block)",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "General Styles / General Work",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 4,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 3,
      "DSGN_SDsgn": 8,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 5,
      "BLD_SDvlpr": 12,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0.25,
      "DPLY_DplmEngnr": 0.75,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Simple",
      "Job type": "New Asset",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 2,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 3,
      "TST_QAMngr": 0,
      "TST_QA": 0.65,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Medium",
      "Job type": "New Asset",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 3,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 5,
      "TST_QAMngr": 0,
      "TST_QA": 1,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Complex",
      "Job type": "New Asset",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 4,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 7,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 1.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Simple SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 3,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 5,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 3,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 3,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Medium SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 5,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 8,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 5,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 4,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Complex SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 8,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 10,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 7,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 5,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Custom SLA",
      "Job type": "New Asset",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "General Styles / General Work",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 2,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 4,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 3,
      "BLD_SDvlpr": 6,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0.25,
      "DPLY_DplmEngnr": 0.75,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Simple",
      "Job type": "Update",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1,
      "TST_QAMngr": 0,
      "TST_QA": 0.35,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Medium",
      "Job type": "Update",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 1,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 2.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Complex",
      "Job type": "Update",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.05,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.05,
      "DSGN_SDsgn": 2,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0.05,
      "BLD_SDvlpr": 3.5,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 1.2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Simple SLA",
      "Job type": "Update",
      "SCPNG_LM": 2,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 3,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Medium SLA",
      "Job type": "Update",
      "SCPNG_LM": 3,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 5,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 3,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Complex SLA",
      "Job type": "Update",
      "SCPNG_LM": 5,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 7,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 4,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master",
      "Item": "Custom SLA",
      "Job type": "Update",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "General Styles / General Work",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 2,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 4,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 4,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 1,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Simple",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 2,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Medium",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.75,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 4,
      "TST_QAMngr": 0,
      "TST_QA": 1,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Complex",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 1.5,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 6,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Simple SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 3,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Medium SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 4,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Complex SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 5,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Custom SLA",
      "Job type": "New Asset",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "General Styles / General Work",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0.5,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 2,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 1,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Simple",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.25,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Medium",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1,
      "TST_QAMngr": 0,
      "TST_QA": 0.25,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Complex",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0.75,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 2,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.3,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Simple SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Medium SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Complex SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Master eMail",
      "Item": "Custom SLA",
      "Job type": "Update",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "General Styles / General Work",
      "Job type": "New Asset (skeleton)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 2,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 16,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 3,
      "BLD_SDvlpr": 8,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 4,
      "DPLY_DplmMngr": 0.25,
      "DPLY_DplmEngnr": 0.75,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Simple",
      "Job type": "New Asset (skeleton)",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 2.5,
      "TST_QAMngr": 0,
      "TST_QA": 1,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Medium",
      "Job type": "New Asset (skeleton)",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 6,
      "TST_QAMngr": 0,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Complex",
      "Job type": "New Asset (skeleton)",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 12,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 3,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Simple SLA",
      "Job type": "New Asset (skeleton)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 3,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 3,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Medium SLA",
      "Job type": "New Asset (skeleton)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 4,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 5,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 3,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Complex SLA",
      "Job type": "New Asset (skeleton)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 5,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 7,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 4,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Custom SLA",
      "Job type": "New Asset (skeleton)",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "General Styles / General Work",
      "Job type": "Update (skeleton)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 2,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 8,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1.5,
      "BLD_SDvlpr": 4,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0.25,
      "DPLY_DplmEngnr": 0.75,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Simple",
      "Job type": "Update (skeleton)",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Medium",
      "Job type": "Update (skeleton)",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 3,
      "TST_QAMngr": 0,
      "TST_QA": 1,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Complex",
      "Job type": "Update (skeleton)",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 6,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 1.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Simple SLA",
      "Job type": "Update (skeleton)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Medium SLA",
      "Job type": "Update (skeleton)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 3,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Complex SLA",
      "Job type": "Update (skeleton)",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 3,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 4,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 3,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Global Grand Master",
      "Item": "Custom SLA",
      "Job type": "Update (skeleton)",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "General Styles / General Work",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0,
      "SCPNG_CH": 4,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.15,
      "DSGN_SDsgn": 16,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0.15,
      "BLD_SDvlpr": 16,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0.1,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Simple",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 1.3125,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 3,
      "DSGN_PR/QA": 0.75,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 4,
      "TST_QAMngr": 0,
      "TST_QA": 1,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Medium",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 1.9875,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 5,
      "DSGN_PR/QA": 0.75,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 6,
      "TST_QAMngr": 0,
      "TST_QA": 1.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Complex",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 2.025,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 7,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 1.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 10,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Simple SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 3,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 7,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 6,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 3,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Medium SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 5,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 10,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 9,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 4,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Complex SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": 8,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 15,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 12,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 5,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Custom SLA",
      "Job type": "New Asset w/o TMPLT",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "General Styles / General Work",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 2,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.15,
      "DSGN_SDsgn": 8,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0.15,
      "BLD_SDvlpr": 8,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0.1,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Simple",
      "Job type": "Update",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.4875,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.75,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Medium",
      "Job type": "Update",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.8625,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 1,
      "DSGN_PR/QA": 0.75,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 3,
      "TST_QAMngr": 0,
      "TST_QA": 1,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Complex",
      "Job type": "Update",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 1.275,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 2,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 1.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 5,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": 0,
      "EXCTN_DplmEngnr": 0
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Simple SLA",
      "Job type": "Update",
      "SCPNG_LM": 2,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 3,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Medium SLA",
      "Job type": "Update",
      "SCPNG_LM": 3,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 5,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 3,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Complex SLA",
      "Job type": "Update",
      "SCPNG_LM": 5,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 7,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 4,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Custom SLA",
      "Job type": "Update",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "General Styles / General Work",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.15,
      "DSGN_SDsgn": 4,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0.15,
      "BLD_SDvlpr": 8,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0.1,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Simple",
      "Job type": "Localisation",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.4125,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.75,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Medium",
      "Job type": "Localisation",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.6375,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 1,
      "DSGN_PR/QA": 0.75,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 1.5,
      "TST_QAMngr": 0,
      "TST_QA": 1,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Complex",
      "Job type": "Localisation",
      "SCPNG_LM": 0.5,
      "SCPNG_CH": 0.975,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 2,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 1.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 3,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Simple SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 2,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Medium SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 3,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 3,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Complex SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 4,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 3,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 5,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 3,
      "TST_QA": 0,
      "DPLY_DplmMngr": 1,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Remote detailing",
      "Item": "Custom SLA",
      "Job type": "Localisation",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "General Styles / General Work",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.15,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0.15,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0.1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Simple",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.75,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Medium",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Complex",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Simple SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Medium SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Complex SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Custom SLA",
      "Job type": "New Asset",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "General Styles / General Work",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.15,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0.15,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0.1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Simple",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Medium",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Complex",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Simple SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Medium SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Complex SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Custom SLA",
      "Job type": "Update",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "General Styles / General Work",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.15,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0.15,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0.1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Simple",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Medium",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Complex",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Simple SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Medium SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Complex SLA",
      "Job type": "Localisation",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "VMI",
      "Item": "Custom SLA",
      "Job type": "Localisation",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "General Styles / General Work",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 2,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0.5,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Simple",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 2,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Medium",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.75,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 4,
      "TST_QAMngr": 0,
      "TST_QA": 1,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Complex",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 6,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Simple SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Medium SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Complex SLA",
      "Job type": "New Asset",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 2,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Custom SLA",
      "Job type": "New Asset",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "General Styles / General Work",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 1,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0.1,
      "DSGN_SDsgn": 1,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0.5,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Simple",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.25,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.25,
      "TST_QAMngr": 0,
      "TST_QA": 0.1,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Medium",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0.5,
      "DSGN_PR/QA": 0.25,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0.5,
      "TST_QAMngr": 0,
      "TST_QA": 0.2,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Complex",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0.75,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0.5,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0.75,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0.5,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Simple SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Medium SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 1,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 1,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Complex SLA",
      "Job type": "Update",
      "SCPNG_LM": 0,
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 2,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 2,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 1,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
    {
      "Asset Type": "Veeva AE Fragment",
      "Item": "Custom SLA",
      "Job type": "Update",
      "SCPNG_LM": "TBD",
      "SCPNG_CH": 0,
      "SCPNG_MedAdv": 0,
      "DSGN_CrtvPM": 0,
      "DSGN_SDsgn": 0,
      "DSGN_Dsgnr": 0,
      "DSGN_PR/QA": 0,
      "BLD_PM": 0,
      "BLD_SDvlpr": 0,
      "BLD_Dvlpr": 0,
      "TST_QAMngr": 0,
      "TST_QA": 0,
      "DPLY_DplmMngr": 0,
      "DPLY_DplmEngnr": 0,
      "EXCTN_DplmMngr": '',
      "EXCTN_DplmEngnr": ''
    },
  ];
}
