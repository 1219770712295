
























































































































































































































































































































import Vue from 'vue';
import { CalculatorInputData } from '@/types/input/CalculatorInputData';
import FlatRateRateCard from '@/data-sources/FlatRateRateCard';
import Utils from '@/helpers/utils';
import Jira from '@/services/Jira';

export default Vue.extend({
  name: 'FlatRateCalculation',
  props: {
    inset: {
      type: Object as () => CalculatorInputData,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    isLocked: {
      type: Boolean,
    },
    isLockedForNonApproverDuringNeedsApproval: {
      type: Boolean,
    },
    isNotInDelivery: {
      type: Boolean,
    },
  },
  data: () => ({
    panels: [1],
    panelsDisabled: false,
    services: [],
    templateService: {
      name: '',
      selectedAssetType: 'eDetailer',
      templateList: [],
      complexityLevelList: [],
      typeList: [],
      template: '',
      pss: '',
      jira: {
        jiraEpic: '',
        jiraSummary: '',
        isEpic: false,
        isCheckingEpic: false,
      },
      total: 0,
      innerTotal: 0,
      profit: 0,
      profitPercentage: 0,
      totalWithoutDiscount: 0,
      discountAmount: 0,
      rows: [],
    },
    templateRow: {
      type: '',
      complexityLevel: '',
      quantity: 0,
      rate: 0,
      innerRate: 0,
      discountPercentage: 0,
      cost: 0,
      innerCost: 0,
    },
    assetTypeList: FlatRateRateCard.getAssetTypeNames(),
  }),
  created() {
    // @ts-ignore
    this.services = JSON.parse(this.inset.jobData.services);
    // @ts-ignore
    if (this.services.length === 0) {
      // @ts-ignore
      this.addService();
      this.setInsetServicesField();
    }
    if (!this.isNotInDelivery) {
      this.panels = [0];
    }
  },
  computed: {
    computedServices() {
      // @ts-ignore
      this.services.forEach((service: any) => {
        service.templateList = FlatRateRateCard.getTemplateList(service.selectedAssetType);
        if (!service.templateList.includes(service.template)) {
          service.template = '';
        }

        service.pssList = FlatRateRateCard.getPSSList(
          service.selectedAssetType,
          service.template,
        );
        if (!service.pssList.includes(service.pss)) {
          service.pss = '';
        }

        service.complexityLevelList = FlatRateRateCard.getComplexityLevelList(
          service.selectedAssetType,
          service.template,
          service.pss,
        );
        service.typeList = FlatRateRateCard.getTypeList(
          service.selectedAssetType,
          service.template,
          service.pss,
        );
        // @ts-ignore
        service.rows.forEach((row) => {
          if (!service.complexityLevelList.includes(row.complexityLevel)) {
            row.complexityLevel = '';
          }

          if (!service.typeList.includes(row.type)) {
            row.type = '';
          }
        });
      });
      this.setRowsRate(this.services);

      this.services.forEach((service) => {
        // @ts-ignore
        service.rows.forEach((row) => {
          this.calculateRowCost(row);
          this.calculateInnerRowCost(row);
        });
      });
      this.services.forEach((service) => this.calculateServiceTotal(service));
      this.services.forEach((service) => this.calculateServiceInnerTotal(service));
      this.services.forEach((service) => this.calculateServiceProfit(service));
      this.services.forEach((service) => this.calculateServiceDiscount(service));
      this.setInsetServicesField();
      return this.services;
    },
    absoluteTotal() {
      // @ts-ignore
      return Utils.roundDecimal(this.services.reduce((acc, service) => acc + service.total, 0.0));
    },
    absoluteInnerTotal() {
      // @ts-ignore
      return this.services.reduce((acc, service) => acc + service.innerTotal, 0.0);
    },
    totalWithoutDiscount() {
      return Utils.roundDecimal(
        // @ts-ignore
        this.services.reduce((acc, service) => acc + service.totalWithoutDiscount, 0.0),
      );
    },
    discountAmount() {
      // @ts-ignore
      return Utils.roundDecimal(this.totalWithoutDiscount - this.absoluteTotal);
    },
    grossProfit() {
      // @ts-ignore
      return Utils.roundDecimal(this.absoluteTotal - this.absoluteInnerTotal);
    },
    grossProfitPercentage() {
      // @ts-ignore
      if (this.absoluteTotal === 0) {
        return 0;
      }
      // @ts-ignore
      return Math.round((this.grossProfit / this.absoluteTotal) * 100);
    },
  },
  methods: {
    setRowsRate(services: Array<any>) {
      services.forEach((service) => {
        service.rows.forEach((row: any) => {
          const rateObj = FlatRateRateCard.getRateObj(
            this.inset.selectedPriceList,
            this.currency,
            service.selectedAssetType,
            row.complexityLevel,
            row.type,
            service.template,
            service.pss,
          );
          row.rate = rateObj.rate;
          row.innerRate = rateObj.innerRate;
        });
      });
    },
    addService() {
      // @ts-ignore
      this.services.push(JSON.parse(JSON.stringify(this.templateService)));
      // @ts-ignore
      this.addRow(this.services[this.services.length - 1]);
      if (!this.panels.includes(this.services.length)) {
        this.panels.push(this.services.length); // expand new panel
      }
    },
    removeService(index: number) {
      this.panels = [];
      if (this.services.length > 1) {
        this.services.splice(index, 1);
      }
    },
    // @ts-ignore
    addRow(service) {
      // @ts-ignore
      service.rows.push(JSON.parse(JSON.stringify(this.templateRow)));
    },
    // @ts-ignore
    removeRow(service, rowIndex) {
      service.rows.splice(rowIndex, 1);
    },
    calculateRowCost(row: Record<any, number | string>) {
      row.cost = Utils.roundDecimal(parseFloat(String(row.rate))
        * parseFloat(String(row.quantity))
        * (1 - parseFloat(String(row.discountPercentage)) / 100));
    },
    calculateInnerRowCost(row: Record<any, number | string>) {
      row.innerCost = Utils.roundDecimal(parseFloat(String(row.innerRate))
        * parseFloat(String(row.quantity)));
    },
    calculateServiceTotal(service: Record<any, any>) {
      service.total = service.rows.reduce(
        (acc: number, row: Record<any, number>) => acc + row.cost, 0.0,
      );
    },
    calculateServiceInnerTotal(service: Record<any, any>) {
      service.innerTotal = service.rows.reduce(
        (acc: number, row: Record<any, number>) => acc + row.innerCost, 0.0,
      );
    },
    calculateServiceProfit(service: Record<any, any>) {
      service.profit = service.total - service.innerTotal;
      if (service.total === 0) {
        service.profitPercentage = 0;
      } else {
        service.profitPercentage = Math.round((service.profit / service.total) * 100);
      }
    },
    calculateServiceDiscount(service: Record<any, any>) {
      const copy = JSON.parse(JSON.stringify(service));
      // @ts-ignore
      copy.rows.forEach((row) => {
        row.discountPercentage = 0;
        this.calculateRowCost(row);
      });
      this.calculateServiceTotal(copy);
      service.totalWithoutDiscount = copy.total;
      service.discountAmount = Utils.roundDecimal(copy.total - service.total);
    },
    setInsetServicesField() {
      this.inset.jobData.services = JSON.stringify(this.services);
    },
    // @ts-ignore
    async verifyJiraEpic(service) {
      service.jira.jiraEpic = service.jira.jiraEpic.replace('.', '').trim();
      if (service.jira.jiraEpic.length === 0) {
        service.jira.isEpic = false;
        return;
      }
      service.jira.isCheckingEpic = true;
      try {
        const data = await Jira.getIsEpicData(service.jira.jiraEpic);
        // console.log('jira response', data);
        if (data.success) {
          service.jira.isEpic = data.result.isEpic;
          service.jira.jiraSummary = data.result.summary;
        } else {
          service.jira.isEpic = false;
        }
      } catch (err) {
        service.jira.isEpic = false;
        console.log('jira response err', err);
      }
      service.jira.isCheckingEpic = false;
    },
    autoCorrectNumber(obj: Record<string, number>, key: string, min = 0) {
      if (obj[key] < min) {
        obj[key] = min;
      }
      if (obj[key].toString() === '') {
        obj[key] = 0;
      }
    },
    autoCorrectPercentageInputField(obj: Record<string, number>, min = 0, max = 99) {
      if (obj.discountPercentage < min) {
        obj.discountPercentage = min;
      }
      if (obj.discountPercentage > max) {
        obj.discountPercentage = max;
      }
      if (obj.discountPercentage.toString() === '') {
        obj.discountPercentage = min;
      }
    },
  },
});
