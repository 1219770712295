export default class CurrencyExchange {
  static euroTo(currency: string) {
    switch (currency) {
      case 'UAH':
        return this.euroToUAH();
      case 'USD':
        return this.euroToUSD();
      case 'RUB':
        return this.euroToRUB();
      default:
        return 1;
    }
  }

  private static euroToUAH() {
    return 45;
  }

  private static euroToRUB() {
    return 60;
  }

  private static euroToUSD() {
    return 1.1;
  }

  private static usdToEUR() {
    return 1 / this.euroToUSD();
  }

  static usdTo(currency: string) {
    switch (currency) {
      case 'UAH':
        return this.usdToEUR() * this.euroToUAH();
      case 'EUR':
        return this.usdToEUR();
      case 'RUB':
        return this.usdToEUR() * this.euroToRUB();
      default:
        return 1;
    }
  }
}
