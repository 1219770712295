
























































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import ErrorPopup from '@/components/modals/ErrorPopup.vue';
import ValidationErrorPopup from '@/components/modals/ValidationErrorPopup.vue';
import SavingSuccessPopup from '@/components/modals/SavingSuccessPopup.vue';
import UnsavedChangesWarningPopup from '@/components/modals/UnsavedChangesWarningPopup.vue';
import CommentPopup from '@/components/modals/CommentPopup.vue';
import { API } from 'aws-amplify';
import {
  createCalculatorInputData, createJobData, updateCalculatorInputData, updateJobData,
} from '@/graphql/mutations';
import { getCalculatorInputData } from '@/graphql/queries';
import ResultSection from '@/components/ResultSection.vue';
import Jobs from '@/data-sources/Jobs';
import Norms from '@/data-sources/Norms';
import ReferenceBook from '@/data-sources/ReferenceBook';
import RateCard from '@/data-sources/RateCard';
import Utils from '@/helpers/utils';
import { CalculatorInputData } from '@/types/input/CalculatorInputData';
import { Norm } from '@/types/Norm';
import { NumberOfSlides } from '@/types/slides/NumberOfSlides';
import { SlidesLevel } from '@/types/slides/SlidesLevel';
import { Job } from '@/types/jobs/Job';
import AuthUtils from '@/helpers/authUtils';
import { InputCheckboxes } from '@/types/input/InputCheckboxes';
import { LevelCost } from '@/types/jobs/LevelCost';
import InputComparator from '@/helpers/inputComparator';
import CustomCalculation from '@/components/CustomCalculation.vue';
import FlatRateCalculation from '@/components/FlatRateCalculation.vue';
import CustomRateCard from '@/data-sources/CustomRateCard';
import CustomAssetTypes from '@/data-sources/CustomAssetTypes';
import Users from '@/services/Users';
import Status from '@/enums/Status';
import DataPreparer from '@/helpers/dataPreparer';
import FlatRateRateCard from '@/data-sources/FlatRateRateCard';
import Jira from '@/services/Jira';

export default Vue.extend({
  name: 'CalculatorWrapper',
  components: {
    ResultSection,
    CustomCalculation,
    FlatRateCalculation,
    ErrorPopup,
    ValidationErrorPopup,
    SavingSuccessPopup,
    UnsavedChangesWarningPopup,
    CommentPopup,
  },
  data: () => ({
    isProductionViewEnabled: false,
    userName: '',
    isAdmin: false,
    isNotInDelivery: false,
    potentialApproversList: [],
    isLoaded: false,
    isSaving: false,
    priceListSearch: '',
    assetTypeSearch: '',
    isChangingStatusFailed: false,
    jira: {
      isCheckingEpic: false,
      isEpic: false,
    },
    errorData: {
      show: false,
      errorObject: {},
      shouldGoHome: false,
    },
    validationErrorData: {
      show: false,
      errorMessage: '',
    },
    showSuccessDialog: false,
    showUnsavedChangesWarning: false,
    showCommentPopup: false,
    inset: {
      id: null,
      name: '',
      type: 'CalculatorInputData',
      status: Status.Draft,
      calculationType: 'standard',
      selectedAssetType: 'Presentation',
      selectedJobType: 'New Asset w TMPLT',
      selectedTargetSystem: 'Veeva CRM',
      selectedScopingComplexity: 'Simple',
      selectedPriceList: 'Global EUR PL',
      jiraEpic: '',
      jiraSummary: '',
      reuseRatioPercentage: 0,
      discountPercentage: 0,
      createdBy: '',
      owner: null,
      currentApproverId: '',
      approvedOrRejectedBy: '',
      rejectedComment: '',
      approvers: [] as Array<string>,
      checkboxes: {
        Scoping: true,
        Design: true,
        Deploy: true,
        Campaign_Execution: false,
      },
      numberOfSlides: {
        simple: { imageBased: 0, html5: 0 } as SlidesLevel,
        medium: { imageBased: 0, html5: 0 } as SlidesLevel,
        complex: { imageBased: 0, html5: 0 } as SlidesLevel,
      } as NumberOfSlides,
      jobData: {
        services: '[]',
      },
    } as CalculatorInputData,
    backupInset: '',
    assetTypeList: Norms.getAssetTypeNames(),
    priceList: RateCard.getPriceList(),
    scopingComplexityList: ReferenceBook.getScopingComplexityList(),
    jobsStructure: Jobs.getJobs(),
    jobsStructureCopyForProfitCalc: Jobs.getJobs(),
    inputDigitFieldRules: [
      (v: string | number) => {
        let isValid = true;
        if (!(typeof v === 'number') && !v) {
          isValid = false;
        } else {
          v.toString()
            .split('')
            .forEach((char) => {
              if (!'0123456789'.includes(char)) {
                isValid = false;
              }
            });
        }
        return isValid || 'Please, put in digit chars only';
      },
    ],
    radioSwitcher: 'radio-Role',
    panels: { slide: [], role: [1] },
    Utils,
    Status,
    servicesTotal: 0.0,
    servicesInnerTotal: 0.0,
    servicesTotalWithoutDiscount: 0.0,
    servicesGrossProfit: 0.0,
  }),
  computed: {
    jobs() {
      const structure = this.jobsStructure;
      this.setRates(structure);
      this.setNormsValues(structure);
      this.setCheckboxes(this.inset);
      this.setJobsTotalRowsPart1(structure); // this has to be before setWorkerFields!
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.jobsStructureCopyForProfitCalc = JSON.parse(JSON.stringify(structure));
      this.setRateAsInnerRate(this.jobsStructureCopyForProfitCalc);
      this.setWorkerFields(structure, this.inset, this.inset.discountPercentage);
      this.setWorkerFields(this.jobsStructureCopyForProfitCalc, this.inset);
      this.setJobsTotalRowsPart2(structure); // this has to be after setWorkerFields!
      this.setJobsTotalRowsPart2(this.jobsStructureCopyForProfitCalc);
      this.calculateLevelsCost(structure);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.inset.jobData.jobs = JSON.stringify(structure);
      return structure;
    },
    currency() {
      if (this.inset.calculationType === 'custom') {
        return CustomRateCard.getCurrency(this.inset.selectedPriceList);
      }
      if (this.inset.calculationType === 'flat rate') {
        return FlatRateRateCard.getCurrency(this.inset.selectedPriceList);
      }
      return RateCard.getCurrency(this.inset.selectedPriceList) as string;
    },
    totalSlidesAmount() {
      return Utils.totalSlidesSum(this.inset.numberOfSlides);
    },
    absoluteTotalHours() {
      // @ts-ignore
      return Utils.getAbsoluteTotalHours(this.jobs);
    },
    absoluteCostPerAsset() {
      // @ts-ignore
      return Utils.getAbsoluteCostPerAsset(this.jobs);
    },
    combinedTotal() {
      // @ts-ignore
      return Utils.roundDecimal(this.absoluteCostPerAsset + this.servicesTotal);
    },
    absoluteInnerCost() {
      // @ts-ignore
      return Utils.getAbsoluteCostPerAsset(this.jobsStructureCopyForProfitCalc);
    },
    grossProfit() {
      // @ts-ignore
      return this.absoluteCostPerAsset - this.absoluteInnerCost;
    },
    combinedGrossProfit() {
      // @ts-ignore
      return Utils.roundDecimal(this.servicesGrossProfit + this.grossProfit);
    },
    combinedGrossProfitPercentage() {
      // @ts-ignore
      if (this.absoluteInnerCost === 0 && this.servicesInnerTotal === 0) {
        return 0;
      }
      // @ts-ignore
      return (this.combinedGrossProfit / this.combinedTotal) * 100;
    },
    totalWithoutDiscount() {
      // @ts-ignore
      return (this.absoluteCostPerAsset * 100) / (100 - this.inset.discountPercentage);
    },
    combinedTotalWithoutDiscount() {
      // @ts-ignore
      return Utils.roundDecimal(this.servicesTotalWithoutDiscount + this.totalWithoutDiscount);
    },
    combinedDiscountAmount() {
      // @ts-ignore
      return Utils.roundDecimal(this.combinedTotalWithoutDiscount - this.combinedTotal);
    },
    jobTypeList() {
      let list: Array<string>;
      if (this.inset.calculationType === 'custom') {
        list = CustomAssetTypes.getJobTypeNames(this.inset.selectedAssetType);
      } else {
        list = Norms.getJobTypeNames(this.inset.selectedAssetType);
      }
      if (!list.includes(this.inset.selectedJobType)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.inset.selectedJobType = list[0];
      }
      return list;
    },
    targetSystemList() {
      const list = ReferenceBook.getTargetSystemNames(this.inset.selectedAssetType);
      if (!list.includes(this.inset.selectedTargetSystem)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.inset.selectedTargetSystem = list[0];
      }
      return list;
    },
    isLocked() {
      return this.inset.status !== Status.Draft;
    },
    isLockedForNonApproverDuringNeedsApproval() {
      if (this.inset.status !== Status.NeedsApproval) {
        return true;
      }
      return !this.inset.approvers.includes(this.userName);
    },
    shouldShowApproveButton() {
      return this.inset.approvers.includes(this.userName)
        && this.inset.status === Status.NeedsApproval;
    },
    shouldShowUnLockButton() {
      return (this.inset.owner === this.userName || this.isAdmin)
        && this.inset.status === Status.Rejected;
    },
  },
  methods: {
    setRates(structure: Array<Job>) {
      structure.forEach((job) => {
        job.jobWorkers.forEach((worker) => {
          worker.rate = RateCard.getRate(this.inset.selectedPriceList, worker.key) as number;
          worker.innerRate = RateCard.getInnerRate(worker.key, this.inset.selectedPriceList);
        });
      });
    },
    setRateAsInnerRate(structure: Array<Job>) {
      structure.forEach((job) => {
        job.jobWorkers.forEach((worker) => {
          worker.rate = worker.innerRate;
        });
      });
    },
    setCheckboxes(inset: CalculatorInputData) {
      if (inset.selectedAssetType !== 'Broadcast E-Mail') {
        inset.checkboxes.Campaign_Execution = false;
      }
    },
    autoCorrectPercentageInputField(field: 'reuseRatioPercentage' | 'discountPercentage', min = 0,
      max = 99) {
      if (this.inset[field] < min) {
        this.inset[field] = min;
      }
      if (this.inset[field] > max) {
        this.inset[field] = max;
      }
      if (this.inset[field].toString() === '') {
        this.inset[field] = min;
      }
    },
    autoCorrectSlidesNumber(obj: SlidesLevel, field: string, min = 0) {
      if (obj[field as keyof SlidesLevel] < min) {
        obj[field as keyof SlidesLevel] = min;
      }
      if (obj[field as keyof SlidesLevel].toString() === '') {
        obj[field as keyof SlidesLevel] = min;
      }
    },
    setNormsValues(structure: Array<Job>) {
      const norms = Norms.getNormsArray(this.inset.selectedAssetType, this.inset.selectedJobType);
      const generalWorkNorm = norms.find((norm) => norm.Item === 'General Styles / General Work');
      const simpleNorm = norms.find((norm) => norm.Item === 'Simple');
      const mediumNorm = norms.find((norm) => norm.Item === 'Medium');
      const complexNorm = norms.find((norm) => norm.Item === 'Complex');
      structure.forEach((job) => {
        job.jobWorkers.forEach((worker) => {
          let generalWork = generalWorkNorm![worker.normsKey as keyof Norm];
          if (typeof generalWork === 'number') {
            // Some values in Norms are strings with %, some are numbers without %. Converting:
            generalWork = `${generalWork * 100}%`;
          }
          worker.generalWorkPercentage = generalWork;
          worker.simple = simpleNorm![worker.normsKey as keyof Norm];
          worker.medium = mediumNorm![worker.normsKey as keyof Norm];
          worker.complex = complexNorm![worker.normsKey as keyof Norm];
        });
      });
    },
    calculateLevelsCost(structure: Array<Job>) {
      this.setWorkersLevelCost(structure);
      this.setJobsLevelCost(structure);
    },
    clearPriceListSearch() {
      this.$nextTick(() => {
        this.priceListSearch = '';
      });
    },
    clearAssetTypeSearch() {
      this.$nextTick(() => {
        this.assetTypeSearch = '';
      });
    },
    setWorkersLevelCost(structure: Array<Job>) {
      structure.forEach((job) => {
        let sumProductNormsSlides = Utils.sumProductNormsSlides(job, this.inset.numberOfSlides);
        sumProductNormsSlides = Utils.roundDecimal(sumProductNormsSlides); // remove x.000000000001
        Object.keys(job.jobWorkers[0].levelCosts).forEach((level: string) => {
          const currentJobOrSlideLevel = level.toLowerCase();
          // @ts-ignore
          let levelSlidesProduct = job[currentJobOrSlideLevel as keyof Job]
            * Utils.slidesSum(currentJobOrSlideLevel, this.inset.numberOfSlides);
          if (job.jobKey === 'Deploy' || job.jobKey === 'Campaign_Execution') {
            levelSlidesProduct = Utils.slidesSum(currentJobOrSlideLevel, this.inset.numberOfSlides);
          }
          let coefficient = 1 / 3; // simple, medium, complex keys in job
          if (sumProductNormsSlides > 0) {
            coefficient = levelSlidesProduct / sumProductNormsSlides;
          }
          job.jobWorkers.forEach((worker) => {
            worker.levelCosts[level as keyof LevelCost].totalHours = Utils.roundDecimal(
              coefficient * worker.totalHours,
            );
            worker.levelCosts[level as keyof LevelCost].totalCost = Utils.roundDecimal(
              coefficient * worker.costPerAsset,
            );
          });
        });
      });
    },
    setJobsLevelCost(structure: Array<Job>) {
      structure.forEach((job) => {
        Object.keys(job.levelCosts).forEach((level) => {
          let totalHours = 0;
          let totalCost = 0;
          job.jobWorkers.forEach((worker) => {
            totalHours += worker.levelCosts[level as keyof LevelCost].totalHours;
            totalCost += worker.levelCosts[level as keyof LevelCost].totalCost;
          });
          job.levelCosts[level as keyof LevelCost].totalHours = Utils.roundDecimal(totalHours);
          job.levelCosts[level as keyof LevelCost].totalCost = Utils.roundDecimal(totalCost);

          const levelSlidesSum = Utils.slidesSum(level.toLowerCase(), this.inset.numberOfSlides);
          if (levelSlidesSum > 0) {
            job.levelCosts[level as keyof LevelCost].costPerSlide = Utils
              .roundDecimal(totalCost / levelSlidesSum);
          } else {
            job.levelCosts[level as keyof LevelCost].costPerSlide = 0;
          }
        });
      });
    },
    setWorkerFields(structure: Array<Job>, inset: CalculatorInputData, discount = 0) {
      structure.forEach((job) => {
        const sumProductNormsSlides = Utils.sumProductNormsSlides(job, inset.numberOfSlides);
        job.jobWorkers.forEach((worker) => {
          worker.totalHoursPerComponent = Utils.getTotalHoursPerComponent(worker, job.jobKey,
            inset);
          worker.hoursPerAsset = Utils.getHoursPerAsset(worker, job, inset, sumProductNormsSlides);
          worker.defaultTotalHours = Utils.getTotalHoursField(worker, this.totalSlidesAmount);
          if (worker.customTotalHours && worker.customTotalHours > 0) {
            worker.totalHours = Number(worker.customTotalHours);
          } else {
            worker.totalHours = worker.defaultTotalHours;
          }
          if (worker.canceledTotalHours) {
            worker.totalHours = 0;
          }
          worker.costPerAsset = Utils.getCostPerAsset(worker, discount);
          worker.slaDays = Utils.getSlaDays(worker, inset);
        });
      });
    },
    setJobsTotalRowsPart1(structure: Array<Job>) {
      structure.forEach((job: Job) => {
        job.simple = Utils.columnSum(job.jobWorkers, 'simple');
        job.medium = Utils.columnSum(job.jobWorkers, 'medium');
        job.complex = Utils.columnSum(job.jobWorkers, 'complex');
      });
    },
    setJobsTotalRowsPart2(structure: Array<Job>) {
      structure.forEach((job: Job) => {
        job.totalHoursPerComponent = Utils.columnSum(job.jobWorkers, 'totalHoursPerComponent');
        job.hoursPerAsset = Utils.columnSum(job.jobWorkers, 'hoursPerAsset');
        job.totalHours = Utils.columnSum(job.jobWorkers, 'totalHours');
        job.costPerAsset = Utils.columnSum(job.jobWorkers, 'costPerAsset');
        job.slaDays = Utils.columnSum(job.jobWorkers, 'slaDays');
      });
      Utils.setTotalHoursPercentages(structure);
      Utils.setCostPerAssetPercentage(structure);
    },
    async setApproversList() {
      // @ts-ignore
      this.potentialApproversList = await Users.getUsersInGroup('Approvers');
    },
    saveButtonClickHandler() {
      this.save(this.inset.status);
    },
    async save(previousConcurrentStatus: string) {
      this.isSaving = true;
      if (!(await this.validate(previousConcurrentStatus))) {
        this.isSaving = false;
        return;
      }
      this.updateApproversField();
      try {
        if (!this.inset.id) {
          await this.create();
        } else {
          await this.update();
        }
      } catch (error) {
        console.log(error);
        this.presentError(error);
      }
      this.isSaving = false;
      this.showSuccessDialog = true;
      this.setBackUpForComparisonOnPageLeave();
    },
    updateApproversField() {
      if (this.inset.status === Status.Approved || this.inset.status === Status.Rejected) {
        return;
      }
      if (this.inset.currentApproverId && this.inset.currentApproverId !== '') {
        this.inset.status = Status.NeedsApproval;
        if (!this.inset.approvers.includes(this.inset.currentApproverId)) {
          this.inset.approvers.push(this.inset.currentApproverId);
        }
      }
    },
    async validate(previousConcurrentStatus: string) {
      let isValid = true;
      this.validationErrorData.errorMessage = '';
      if (this.inset.name.trim().length === 0) {
        isValid = false;
        this.validationErrorData.errorMessage += 'Please, enter an estimation name.<br/>';
      }
      if (previousConcurrentStatus === Status.Draft && this.inset.calculationType !== 'flat rate') {
        await this.verifyJiraEpic(); // do not verify epics for flat rate type during save
      }
      if (this.inset.calculationType !== 'flat rate' && !this.jira.isEpic && this.inset.jiraEpic) {
        isValid = false;
        this.validationErrorData.errorMessage += 'Please, put in a valid JIRA Epic ID.<br/>';
      }
      /* if (this.inset.calculationType === 'flat rate') {
        const services = JSON.parse(this.inset.jobData.services);
        // @ts-ignore
        if (services.some((service) => !service.jira.isEpic)) {
          isValid = false;
          this.validationErrorData.errorMessage +=
           'Please, enter valid JIRA Epic IDs for all services.<br/>';
        }
      } */
      if (this.inset.calculationType !== 'standard') { // do not validate for standard
        const services = JSON.parse(this.inset.jobData.services);
        // @ts-ignore
        if (services.some((service) => service.name.trim() === '')) {
          isValid = false;
          this.validationErrorData.errorMessage += 'Please, enter valid services names.<br/>';
        }
      }
      if (await this.isStatusChangedByAnotherUser(previousConcurrentStatus)) {
        isValid = false;
        this.validationErrorData.errorMessage = 'This calculation has been changed by another user'
          + '<br/>Please reload the page.';
        this.isChangingStatusFailed = true;
      }
      if (!isValid) {
        this.validationErrorData.show = true;
      }
      return isValid;
    },
    async create() {
      const jobDataResult = await API.graphql({
        query: createJobData,
        variables: { input: this.inset.jobData },
      });
      // @ts-ignore
      this.inset.createdBy = await AuthUtils.getCurrentUserEmail();
      this.inset.owner = this.userName;
      // @ts-ignore
      this.inset.jobData.id = jobDataResult.data.createJobData.id;
      const result = await API.graphql({
        query: createCalculatorInputData,
        variables: { input: DataPreparer.createInput(this.inset) },
      });
      // console.log('result');
      // console.log(result);
      // @ts-ignore
      this.inset = result.data.createCalculatorInputData;
      this.setBackUpForComparisonOnPageLeave();
      await this.$router.replace({
        name: 'CalculatorPage',
        params: { id: this.inset.id! },
      });
    },
    async update() {
      // @ts-ignore
      delete this.inset.jobData.createdAt;
      // @ts-ignore
      delete this.inset.jobData.updatedAt;
      let query = updateJobData;
      if (!this.inset.jobData.id) {
        query = createJobData;
      }
      const jobDataResult = await API.graphql({
        query,
        variables: { input: this.inset.jobData },
      });
      if (!this.inset.jobData.id) {
        // @ts-ignore
        this.inset.jobData.id = jobDataResult.data.createJobData.id;
      }
      // const result =
      await API.graphql({
        query: updateCalculatorInputData,
        variables: { input: DataPreparer.createInput(this.inset) },
      });
      // console.log('result');
      // console.log(result);
    },
    async verifyJiraEpic() {
      this.inset.jiraEpic = this.inset.jiraEpic.replace('.', '').trim();
      if (this.inset.jiraEpic.length === 0) {
        this.jira.isEpic = false;
        return;
      }
      this.jira.isCheckingEpic = true;
      try {
        const data = await Jira.getIsEpicData(this.inset.jiraEpic);
        // console.log('jira response', data);
        if (data.success) {
          this.jira.isEpic = data.result.isEpic;
          this.inset.jiraSummary = data.result.summary;
        } else {
          this.jira.isEpic = false;
        }
      } catch (err) {
        this.jira.isEpic = false;
        this.validationErrorData.errorMessage = err;
        this.validationErrorData.show = true;
        console.log('jira response err', err);
      }
      this.jira.isCheckingEpic = false;
    },
    isJobToShow(jobKey: string) {
      return !Object.keys(this.inset.checkboxes).includes(jobKey)
        || this.inset.checkboxes[jobKey as keyof InputCheckboxes];
    },
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    presentError(msg: any, shouldGoHome = false) {
      this.errorData.show = true;
      this.errorData.errorObject = msg;
      this.errorData.shouldGoHome = shouldGoHome;
    },
    setBackUpForComparisonOnPageLeave() {
      this.backupInset = JSON.stringify(this.inset);
    },
    hasUnsavedChanges() {
      return !InputComparator.isEqual(this.inset, this.backupInset);
    },
    discardChanges() {
      this.setBackUpForComparisonOnPageLeave();
      this.$router.push({ name: 'List' });
    },
    async approve() {
      this.inset.status = Status.Approved;
      this.inset.approvedOrRejectedBy = await AuthUtils.getCurrentUserEmail();
      await this.save(Status.NeedsApproval);
    },
    async reject(comment: string) {
      const currentStatus = this.inset.status;
      this.showCommentPopup = false;
      this.inset.status = Status.Rejected;
      this.inset.approvedOrRejectedBy = await AuthUtils.getCurrentUserEmail();
      this.inset.rejectedComment = comment;
      await this.save(currentStatus);
    },
    async unlock() {
      this.inset.status = Status.Draft;
      this.inset.currentApproverId = '';
      this.inset.approvers = [];
      this.inset.approvedOrRejectedBy = '';
      this.inset.rejectedComment = '';
      await this.save(Status.Rejected);
    },
    async isStatusChangedByAnotherUser(previousConcurrentStatus: string) {
      if (!this.inset.id) {
        return false;
      }
      const result = await this.getCalculatorInputDataById(this.inset.id);
      // @ts-ignore
      const dataOnServer = result.data.getCalculatorInputData;
      if (!dataOnServer) {
        console.log('calculation deleted');
        return true;
      }
      return !(previousConcurrentStatus === dataOnServer.status);
    },
    getCalculatorInputDataById(id: string) {
      return API.graphql({
        query: getCalculatorInputData,
        variables: { id },
      });
    },
    servicesTotalChange(total: number) {
      this.servicesTotal = total;
    },
    servicesInnerTotalChange(total: number) {
      this.servicesInnerTotal = total;
    },
    servicesTotalWithoutDiscountChange(total: number) {
      this.servicesTotalWithoutDiscount = total;
    },
    servicesGrossProfitChange(profit: number) {
      this.servicesGrossProfit = profit;
    },
    runAutoTest() {
      this.inset.numberOfSlides.medium.html5 = 10;
      this.inset.numberOfSlides.simple.html5 = 10;
      this.inset.numberOfSlides.complex.html5 = 10;
      const makeRangeIterator = () => {
        let nextIndex = 0;
        let nextIndexJob = 0;
        let nextIndexTarget = 0;
        const rangeIterator = {
          next: () => {
            let result;
            if (nextIndex < this.assetTypeList.length) {
              this.inset.selectedAssetType = this.assetTypeList[nextIndex];
              if (nextIndexJob < this.jobTypeList.length) {
                this.inset.selectedJobType = this.jobTypeList[nextIndexJob];
                if (nextIndexTarget < this.targetSystemList.length) {
                  this.inset.selectedTargetSystem = this.targetSystemList[nextIndexTarget];
                  result = { value: `${this.inset.selectedAssetType} ${this.inset.selectedJobType} ${this.inset.selectedTargetSystem}`, done: false };
                  nextIndexTarget += 1;
                } else {
                  result = { value: 'switching job', done: false };
                  nextIndexTarget = 0;
                  nextIndexJob += 1;
                }
              } else {
                result = { value: 'switching asset', done: false };
                nextIndexJob = 0;
                nextIndex += 1;
              }
              return result;
            }
            return { value: '', done: true };
          },
        };
        return rangeIterator;
      };
      const test = makeRangeIterator();
      const interval1 = setInterval(() => {
        console.log(test.next());
        if (test.next().done) {
          clearInterval(interval1);
        }
      }, 1000);
    },
  },
  async created() {
    const id = this.$route.params.id;
    if (id) {
      try {
        const result = await this.getCalculatorInputDataById(id);
        // @ts-ignore
        const data = result.data.getCalculatorInputData;
        if (!data) {
          throw new Error('notFound');
        }
        // console.log(JSON.parse(JSON.stringify(data)));
        this.inset = data;
        if (this.inset.jobData.jobs) {
          this.jobsStructure = JSON.parse(this.inset.jobData.jobs);
        }
        this.jira.isEpic = !!this.inset.jiraEpic;
        // do not show error on page load for already saved records
        // @ts-ignore
        delete this.inset.createdAt;
        // @ts-ignore
        delete this.inset.updatedAt;
      } catch (error) {
        if (error.toString().includes('notFound')) {
          this.presentError(
            {
              text: `The record has not been found for the following id: ${id}`,
            },
            true,
          );
        } else if (JSON.stringify(error).includes('Not Authorized')) {
          this.presentError(
            {
              text: `You are not authorized to view the record with the following id: ${id}`,
            },
            true,
          );
        } else {
          this.presentError(error);
        }
      }
    } else if (this.$route.name === 'NewCustomItem') {
      this.inset.calculationType = 'custom';
      this.inset.selectedAssetType = 'Change management - consulting';
    } else if (this.$route.name === 'NewFlatRateItem') {
      this.inset.calculationType = 'flat rate';
      this.inset.selectedAssetType = '';
      this.inset.selectedJobType = '';
    }
    if (this.inset.calculationType === 'custom') { // set lists for existing calculations
      this.priceList = CustomRateCard.getPriceList();
      this.assetTypeList = CustomAssetTypes.getAssetTypeNames();
    }
    if (this.inset.calculationType === 'flat rate') { // set lists for existing calculations
      this.priceList = FlatRateRateCard.getPriceList();
    }

    await this.setApproversList();
    this.setBackUpForComparisonOnPageLeave();
    this.userName = await AuthUtils.getUserName();
    this.isAdmin = await AuthUtils.isAdmin();
    this.isNotInDelivery = await AuthUtils.isNotInDelivery();
    this.isLoaded = true;

    // auto test asset + job + system combinations
    // this.runAutoTest();
  },
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.hasUnsavedChanges()) {
      this.showUnsavedChangesWarning = true;
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
});
