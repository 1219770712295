import { ScopingComplexity } from '@/types/ScopingComplexity';

export default class ReferenceBook {
  public static maxSlidesLevels = {
    level1: {
      value: 40,
      index: 1,
    },
    level2: {
      value: 80,
      index: 2,
    },
    level3: {
      value: 100000,
      index: 3,
    },
  };

  public static emailTargetSystemIndex = {
    'SF Marketing Cloud': {
      scopingIndex: 1.00,
      hours: 2.00,
    },
    'OCE Marketing': {
      scopingIndex: 1.00,
      hours: 2.00,
    },
    MailChimp: { // should be MailChimp, not Mailchimp
      scopingIndex: 0.50,
      hours: 1.00,
    },
    Other: {
      scopingIndex: 1.25,
      hours: 2.50,
    },
  };

  static getScopingComplexityList() {
    return Object.keys(this.scopingComplexityData);
  }

  static getScopingComplexityValue(key: keyof ScopingComplexity) {
    return this.scopingComplexityData[key];
  }

  static getTargetSystemIndex(assetType: string, targetSystem: string, jobKey: string) {
    return this.targetSystemData.find((item) => item['Asset type'] === assetType
      && item['Target system'] === targetSystem
      && item['Job key'] === jobKey)!['System complexity index'];
  }

  static getTargetSystemNames(assetType: string) {
    const names: string[] = [];
    this.targetSystemData.forEach((item) => {
      if ((assetType === item['Asset type']) && !names.includes(item['Target system'])) {
        names.push(item['Target system']);
      }
    });
    return names;
  }

  private static scopingComplexityData: ScopingComplexity = {
    Simple: 0.50,
    Medium: 0.75,
    Complex: 1.00,
  };

  static getSlaLevel(assetType = 'Presentation', slidesAmount = 0) {
    const levelData = this.slaLevels.find((data) => data['Asset type'] === assetType);
    if (slidesAmount >= levelData!['Complex SLA']) {
      return 'Custom SLA';
    }
    if (slidesAmount >= levelData!['Medium SLA']) {
      return 'Complex SLA';
    }
    if (slidesAmount >= levelData!['Simple SLA']) {
      return 'Medium SLA';
    }
    return 'Simple SLA';
  }

  private static slaLevels = [
    {
      'Asset type': 'Presentation',
      'Simple SLA': 15,
      'Medium SLA': 25,
      'Complex SLA': 30,
      'Custom SLA': null,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Simple SLA': 5,
      'Medium SLA': 8,
      'Complex SLA': 10,
      'Custom SLA': null,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Simple SLA': 5,
      'Medium SLA': 8,
      'Complex SLA': 10,
      'Custom SLA': null,
    },
    {
      'Asset type': 'Global Grand Master',
      'Simple SLA': 5,
      'Medium SLA': 10,
      'Complex SLA': 15,
      'Custom SLA': null,
    },
    {
      'Asset type': 'Global Master',
      'Simple SLA': 15,
      'Medium SLA': 25,
      'Complex SLA': 30,
      'Custom SLA': null,
    },
    {
      'Asset type': 'Global Master eMail',
      'Simple SLA': 5,
      'Medium SLA': 8,
      'Complex SLA': 10,
      'Custom SLA': null,
    },
    {
      'Asset type': 'Remote detailing',
      'Simple SLA': 15,
      'Medium SLA': 25,
      'Complex SLA': 30,
      'Custom SLA': null,
    },
    {
      'Asset type': 'Veeva AE Fragment',
      'Simple SLA': 5,
      'Medium SLA': 8,
      'Complex SLA': 10,
      'Custom SLA': null,
    },
    {
      'Asset type': 'VMI',
      'Simple SLA': 15,
      'Medium SLA': 25,
      'Complex SLA': 30,
      'Custom SLA': null,
    },
    {
      'Asset type': 'Quiz_draft',
      'Simple SLA': 15,
      'Medium SLA': 25,
      'Complex SLA': 30,
      'Custom SLA': null,
    },
    {
      'Asset type': 'PPT',
      'Simple SLA': 15,
      'Medium SLA': 25,
      'Complex SLA': 30,
      'Custom SLA': null,
    },
  ];

  private static targetSystemData = [
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva Engage',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'OCE IQVIA',
      'Job key': 'Build',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Nexxus MiTouch',
      'Job key': 'Build',
      'System complexity index': 1.3,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Abbott RU CLM',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Pharmatouch',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'CT Presenter',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Build',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Other',
      'Job key': 'Build',
      'System complexity index': 2.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Nexxus MI Touch',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Agnitio Rainmaker',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Baytouch',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Studium',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM (iOS + Win)',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'iSelling + OCE',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva Engage',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'OCE IQVIA',
      'Job key': 'Test',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Nexxus MiTouch',
      'Job key': 'Test',
      'System complexity index': 1.3,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Abbott RU CLM',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Pharmatouch',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'CT Presenter',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Other',
      'Job key': 'Test',
      'System complexity index': 2.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Nexxus MI Touch',
      'Job key': 'Test',
      'System complexity index': 2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Agnitio Rainmaker',
      'Job key': 'Test',
      'System complexity index': 2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Baytouch',
      'Job key': 'Test',
      'System complexity index': 2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Studium',
      'Job key': 'Test',
      'System complexity index': 2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM (iOS + Win)',
      'Job key': 'Test',
      'System complexity index': 2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'iSelling + OCE',
      'Job key': 'Test',
      'System complexity index': 2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva Engage',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'OCE IQVIA',
      'Job key': 'Deploy',
      'System complexity index': 1.3,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Nexxus MiTouch',
      'Job key': 'Deploy',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Abbott RU CLM',
      'Job key': 'Deploy',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Pharmatouch',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'CT Presenter',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Other',
      'Job key': 'Deploy',
      'System complexity index': 2.5,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Nexxus MI Touch',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Agnitio Rainmaker',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Baytouch',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM + Studium',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'Veeva CRM (iOS + Win)',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Presentation',
      'Target system': 'iSelling + OCE',
      'Job key': 'Deploy',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'Veeva CRM',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'SF Marketing Cloud',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'OCE Marketing',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'MailChimp',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'MI Nexxus',
      'Job key': 'Build',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'TeamSoft (Pharma HRM)',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'Other',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'Veeva CRM',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'SF Marketing Cloud',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'OCE Marketing',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'MailChimp',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'MI Nexxus',
      'Job key': 'Test',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'TeamSoft (Pharma HRM)',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'Other',
      'Job key': 'Test',
      'System complexity index': 2,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'Veeva CRM',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'SF Marketing Cloud',
      'Job key': 'Deploy',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'OCE Marketing',
      'Job key': 'Deploy',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'MailChimp',
      'Job key': 'Deploy',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'MI Nexxus',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'TeamSoft (Pharma HRM)',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Approved E-Mail',
      'Target system': 'Other',
      'Job key': 'Deploy',
      'System complexity index': 2,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Veeva CRM',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Veeva Engage',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'OCE IQVIA',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Nexxus MiTouch',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Abbott RU CLM',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Pharmatouch',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'CT Presenter',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Other',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Veeva CRM',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Veeva Engage',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'OCE IQVIA',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Nexxus MiTouch',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Abbott RU CLM',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Pharmatouch',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'CT Presenter',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Other',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Veeva CRM',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Veeva Engage',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'OCE IQVIA',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Nexxus MiTouch',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Abbott RU CLM',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Pharmatouch',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'CT Presenter',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Grand Master',
      'Target system': 'Other',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Veeva CRM',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Veeva Engage',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'OCE IQVIA',
      'Job key': 'Build',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Nexxus MiTouch',
      'Job key': 'Build',
      'System complexity index': 1.3,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Abbott RU CLM',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Pharmatouch',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'CT Presenter',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Build',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Other',
      'Job key': 'Build',
      'System complexity index': 2.5,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Veeva CRM',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Veeva Engage',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'OCE IQVIA',
      'Job key': 'Test',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Nexxus MiTouch',
      'Job key': 'Test',
      'System complexity index': 1.3,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Abbott RU CLM',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Pharmatouch',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'CT Presenter',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Other',
      'Job key': 'Test',
      'System complexity index': 2.5,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Veeva CRM',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Veeva Engage',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'OCE IQVIA',
      'Job key': 'Deploy',
      'System complexity index': 1.3,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Nexxus MiTouch',
      'Job key': 'Deploy',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Abbott RU CLM',
      'Job key': 'Deploy',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Pharmatouch',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'CT Presenter',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master',
      'Target system': 'Other',
      'Job key': 'Deploy',
      'System complexity index': 2.5,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Veeva Engage for Portals',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Viseven CRM',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Abbott Remote Calls',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Kadrige (IQVIA)',
      'Job key': 'Build',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'CT Presenter',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Other',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Veeva Engage for Portals',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Viseven CRM',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Abbott Remote Calls',
      'Job key': 'Test',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Kadrige (IQVIA)',
      'Job key': 'Test',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'CT Presenter',
      'Job key': 'Test',
      'System complexity index': 2,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Other',
      'Job key': 'Test',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Veeva Engage for Portals',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Viseven CRM',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Abbott Remote Calls',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Kadrige (IQVIA)',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'CT Presenter',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Remote detailing',
      'Target system': 'Other',
      'Job key': 'Deploy',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Veeva CRM',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'SF Marketing Cloud',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'OCE Marketing',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'MailChimp',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Pitcher',
      'Job key': 'Build',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'MI Nexxus',
      'Job key': 'Build',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'TeamSoft (Pharma HRM)',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Other',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Veeva CRM',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'SF Marketing Cloud',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'OCE Marketing',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'MailChimp',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Pitcher',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'MI Nexxus',
      'Job key': 'Test',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'TeamSoft (Pharma HRM)',
      'Job key': 'Test',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Other',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Veeva CRM',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'SF Marketing Cloud',
      'Job key': 'Deploy',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'OCE Marketing',
      'Job key': 'Deploy',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'MailChimp',
      'Job key': 'Deploy',
      'System complexity index': 1.1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Agnitio Rainmaker',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Pitcher',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'MI Nexxus',
      'Job key': 'Deploy',
      'System complexity index': 1.2,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'TeamSoft (Pharma HRM)',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Global Master eMail',
      'Target system': 'Other',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'SF Marketing Cloud',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'OCE Marketing',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'MailChimp',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'Other',
      'Job key': 'Build',
      'System complexity index': 1.5,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'SF Marketing Cloud',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'OCE Marketing',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'MailChimp',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'Other',
      'Job key': 'Test',
      'System complexity index': 2,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'SF Marketing Cloud',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'OCE Marketing',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'MailChimp',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Broadcast E-Mail',
      'Target system': 'Other',
      'Job key': 'Deploy',
      'System complexity index': 2,
    },
    {
      'Asset type': 'VMI',
      'Target system': 'Veeva CRM',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'VMI',
      'Target system': 'Veeva CRM',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'VMI',
      'Target system': 'Veeva CRM',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Veeva AE Fragment',
      'Target system': 'Veeva CRM',
      'Job key': 'Build',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Veeva AE Fragment',
      'Target system': 'Veeva CRM',
      'Job key': 'Test',
      'System complexity index': 1,
    },
    {
      'Asset type': 'Veeva AE Fragment',
      'Target system': 'Veeva CRM',
      'Job key': 'Deploy',
      'System complexity index': 1,
    },
  ];
}
