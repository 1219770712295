export default class Parsers {
  static parseError(errorObject: any) {
    if (errorObject && errorObject.errors && errorObject.errors.length) {
      return errorObject.errors[0].message;
    }
    if (errorObject && errorObject.message) {
      return errorObject.message;
    }
    if (errorObject && errorObject.text) {
      return errorObject.text;
    }
    if (typeof errorObject === 'string') {
      return errorObject;
    }
    return errorObject.toString();
  }
}
