





























































import Vue from 'vue';
import { Auth, Hub } from 'aws-amplify';
import AuthUtils from '@/helpers/authUtils';
import ErrorPopup from '@/components/modals/ErrorPopup.vue';

const federatedIdName = 'OpenID-auth';

export default Vue.extend({
  name: 'App',
  components: {
    ErrorPopup,
  },
  data: () => ({
    profile: {
      email: '',
      fullName: '',
    },
    state: {
      signedIn: false,
    },
    errorData: {
      show: false,
      errorObject: {},
    },
  }),
  created() {
    this.listenToAuthEvents();
    this.checkUser();
  },
  methods: {
    redirectToLogin() {
      // @ts-ignore
      Auth.federatedSignIn({ provider: federatedIdName });
      // Auth.confirmSignIn(user, code); // Todo to try to get refresh token
    },
    presentError(msg: Record<string, any>) {
      this.errorData.show = true;
      this.errorData.errorObject = msg;
    },
    checkUser() {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (user) {
            this.state.signedIn = true;
            // console.log('user', user);
            this.setProfileInfo();
          }
        })
        .catch((err) => {
          console.log(err);
          const query = this.$route.query;
          if (query.signed_out) {
            return;
          }
          if (!this.$route.hash.includes('error')) {
            this.redirectToLogin();
          }
        });
    },
    signOut() {
      Auth.signOut()
        .then((data) => {
          this.state.signedIn = false;
          console.log('signOut data', data);
        })
        .catch((err) => console.log(err));
    },
    async setProfileInfo() {
      // console.log('session:');
      // console.log(await Auth.currentSession());
      this.profile.email = await AuthUtils.getCurrentUserEmail();
      this.profile.fullName = await AuthUtils.getCurrentUserName();
    },
    listenToAuthEvents() {
      // @ts-ignore
      Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          case 'signIn':
            // console.log('signIn event fired', data);
            this.state.signedIn = true;
            break;
          case 'cognitoHostedUI':
            // console.log('cognitoHostedUI event fired', data);
            break;
          case 'signOut':
            console.log('signOut event fired', data);
            break;
          case 'signIn_failure':
            console.log('signIn_failure Sign in failure', data);
            this.state.signedIn = false;
            this.presentError(
              {
                text: 'Login Error. Please, request access in IT Helpdesk (Access management).',
              },
            );
            break;
          case 'cognitoHostedUI_failure':
            console.log('cognitoHostedUI_failure Sign in failure', data);
            break;
          case 'tokenRefresh':
            console.info('token refresh succeeded');
            break;
          case 'tokenRefresh_failure':
            console.error('token refresh failed');
            break;
          case 'configured':
            console.info('the Auth module is configured');
            break;
          default:
            console.log('default case event', event);
            console.log('default case data', data);
            break;
        }
      });
    },
  },
});
