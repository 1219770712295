import { Job } from '@/types/jobs/Job';

export default class Jobs {
  static getSpecialistList() {
    const specialists: Record<string, string>[] = [];
    this.getJobs().forEach((job) => {
      job.jobWorkers.forEach((worker) => {
        // const jobName = job.jobKey.replace('_', ' ');
        specialists.push({ id: worker.key, name: `${worker.title} (${worker.key})` });
      });
    });
    specialists.push({ id: 'other services', name: 'other services' });
    return specialists;
  }

  static getJobs(): Array<Job> {
    return [
      {
        jobKey: 'Scoping',
        simple: 0.0,
        medium: 0.0,
        complex: 0.0,
        totalHoursPerComponent: 0.0,
        hoursPerAsset: 0.0,
        totalHours: 0.0,
        totalHoursPercentage: 0,
        costPerAsset: 0.0,
        costPerAssetPercentage: 0,
        slaDays: 0.0,
        levelCosts: {
          Simple: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Medium: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Complex: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
        },
        jobWorkers: [
          {
            key: 'LM',
            title: 'Liaison Manager',
            normsKey: 'SCPNG_LM',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
          {
            key: 'CH',
            title: 'Channel Manager',
            normsKey: 'SCPNG_CH',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
          {
            key: 'MedAdv',
            title: 'Medical Marketing Expert',
            normsKey: 'SCPNG_MedAdv',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
        ],
      },
      {
        jobKey: 'Design',
        simple: 0.0,
        medium: 0.0,
        complex: 0.0,
        totalHoursPerComponent: 0.0,
        hoursPerAsset: 0.0,
        totalHours: 0.0,
        totalHoursPercentage: 0,
        costPerAsset: 0.0,
        costPerAssetPercentage: 0,
        slaDays: 0.0,
        levelCosts: {
          Simple: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Medium: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Complex: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
        },
        jobWorkers: [
          {
            key: 'CrtvPM',
            title: 'Project Manager',
            normsKey: 'DSGN_CrtvPM',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
          {
            key: 'SDsgn',
            title: 'Senior Graphic Designer',
            normsKey: 'DSGN_SDsgn',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
          {
            key: 'Dsgnr',
            title: 'Graphic Designer',
            normsKey: 'DSGN_Dsgnr',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
          {
            key: 'PR/QA',
            title: 'Proofreader',
            normsKey: 'DSGN_PR/QA',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
        ],
      },
      {
        jobKey: 'Build',
        simple: 0.0,
        medium: 0.0,
        complex: 0.0,
        totalHoursPerComponent: 0.0,
        hoursPerAsset: 0.0,
        totalHours: 0.0,
        totalHoursPercentage: 0,
        costPerAsset: 0.0,
        costPerAssetPercentage: 0,
        slaDays: 0.0,
        levelCosts: {
          Simple: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Medium: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Complex: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
        },
        jobWorkers: [
          {
            key: 'PM',
            title: 'Project Manager',
            normsKey: 'BLD_PM',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
          {
            key: 'SDvlpr',
            title: 'Frontend Developer',
            normsKey: 'BLD_SDvlpr',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
          {
            key: 'Dvlpr',
            title: 'HTML 5 Coder',
            normsKey: 'BLD_Dvlpr',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
        ],
      },
      {
        jobKey: 'Test',
        simple: 0.0,
        medium: 0.0,
        complex: 0.0,
        totalHoursPerComponent: 0.0,
        hoursPerAsset: 0.0,
        totalHours: 0.0,
        totalHoursPercentage: 0,
        costPerAsset: 0.0,
        costPerAssetPercentage: 0,
        slaDays: 0.0,
        levelCosts: {
          Simple: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Medium: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Complex: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
        },
        jobWorkers: [
          {
            key: 'QAMngr',
            title: 'Senior QA Engineer',
            normsKey: 'TST_QAMngr',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
          {
            key: 'QA',
            title: 'QA Engineer',
            normsKey: 'TST_QA',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
        ],
      },
      {
        jobKey: 'Deploy',
        simple: 0.0,
        medium: 0.0,
        complex: 0.0,
        totalHoursPerComponent: 0.0,
        hoursPerAsset: 0.0,
        totalHours: 0.0,
        totalHoursPercentage: 0,
        costPerAsset: 0.0,
        costPerAssetPercentage: 0,
        slaDays: 0.0,
        levelCosts: {
          Simple: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Medium: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Complex: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
        },
        jobWorkers: [
          {
            key: 'DplmMngr',
            title: 'Project Manager',
            normsKey: 'DPLY_DplmMngr',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
          {
            key: 'DplmEngnr',
            title: 'Content Deployment Engineer',
            normsKey: 'DPLY_DplmEngnr',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
        ],
      },
      {
        jobKey: 'Campaign_Execution',
        simple: 0.0,
        medium: 0.0,
        complex: 0.0,
        totalHoursPerComponent: 0.0,
        hoursPerAsset: 0.0,
        totalHours: 0.0,
        totalHoursPercentage: 0,
        costPerAsset: 0.0,
        costPerAssetPercentage: 0,
        slaDays: 0.0,
        levelCosts: {
          Simple: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Medium: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
          Complex: {
            totalHours: 0,
            totalCost: 0,
            costPerSlide: 0,
          },
        },
        jobWorkers: [
          {
            key: 'DplmMngr',
            title: 'Project Manager',
            normsKey: 'EXCTN_DplmMngr',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
          {
            key: 'DplmEngnr',
            title: 'Content Deployment Engineer',
            normsKey: 'EXCTN_DplmEngnr',
            rate: 0,
            innerRate: 0,
            generalWorkPercentage: '',
            simple: 0.0,
            medium: 0.0,
            complex: 0.0,
            totalHoursPerComponent: 0.0,
            hoursPerAsset: 0.0,
            totalHours: 0.0,
            defaultTotalHours: 0.0,
            customTotalHours: 0.0,
            canceledTotalHours: false,
            costPerAsset: 0.0,
            slaDays: 0.0,
            levelCosts: {
              Simple: {
                totalHours: 0,
                totalCost: 0,
              },
              Medium: {
                totalHours: 0,
                totalCost: 0,
              },
              Complex: {
                totalHours: 0,
                totalCost: 0,
              },
            },
          },
        ],
      },
    ];
  }
}
